import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import { IAppState } from '@store/features/app/app.types';

const initialState: IAppState = {
  isFaqPopupOpen: false,
  isTruckerFaqPopupOpen: false,
  isTruckerAboutPopupOpen: false,
  isTruckerHowItWorksPopupOpen: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsFaqPopupOpen: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isFaqPopupOpen = payload;
    },
    setIsTruckerFaqPopupOpen: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isTruckerFaqPopupOpen = payload;
    },
    setIsTruckerAboutPopupOpen: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isTruckerAboutPopupOpen = payload;
    },
    setIsTruckerHowItWorksPopupOpen: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isTruckerHowItWorksPopupOpen = payload;
    },
  },
});

export const { setIsFaqPopupOpen, setIsTruckerFaqPopupOpen, setIsTruckerAboutPopupOpen,
  setIsTruckerHowItWorksPopupOpen } = appSlice.actions;

export default appSlice.reducer;
