import Box from '@mui/material/Box';
import { StyledMenuItem } from '@components/AppBar/styled';
import { useDispatch } from 'react-redux';

import { PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages';

import { setIsOpenContactUsPopupAction } from '@store/features/auth/auth.actions';
import { useRouter } from '@hooks/useRouter';

const TruckerNavbar = () => {
  const dispatch = useDispatch();
  const { navigate } = useRouter();

  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: '37px',
        alignItems: 'center',
      }}
    >
      <StyledMenuItem onClick={() => navigate(PAGE_ROUTES_PUBLIC.AFFILIATE)}>
        Referral Partner
      </StyledMenuItem>
      <StyledMenuItem onClick={() => navigate('/trucker-landing')}>
        Trucker partner
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => dispatch(setIsOpenContactUsPopupAction(true))}
      >
        Contact us
      </StyledMenuItem>
    </Box>
  );
};

export default TruckerNavbar;
