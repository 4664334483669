import { ReactNode } from 'react';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from '@theme';

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const nextTheme = { ...theme };

  return (
    <StyledEngineProvider injectFirst>
      <StyledThemeProvider theme={nextTheme}>
        <CssBaseline />
        <MuiThemeProvider theme={nextTheme}>{children}</MuiThemeProvider>
      </StyledThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
