import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import { IAuthState } from '@store/features/auth/auth.types';
import { StorageKeysEnum } from '@utils/constants';
import {
  getUserInfoThunk,
  loginThunk,
  logoutThunk,
  registerThunk,
  updatePasswordThunk,
} from '@store/features/auth/auth.thunks';
import StorageManager from '@utils/storage-manager';

const initialState: IAuthState = {
  accessToken: StorageManager.getString(StorageKeysEnum.ACCESS_TOKEN) || '',
  refreshToken: StorageManager.getString(StorageKeysEnum.REFRESH_TOKEN) || '',
  setupTime: StorageManager.getItem(StorageKeysEnum.SETUP_TIME) || '',
  isOpenRegisterPopup: false,
  isOpenLoginPopup: false,
  isOpenResetPasswordPopup: false,
  isOpenContactUsPopup: false,
  isAuth: false,
  errors: null,
  login: {
    isLoading: false,
    error: '',
  },
  register: {
    isLoading: false,
    error: '',
  },
  user: StorageManager.getItem(StorageKeysEnum.USER) || null,
  userIsLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: () => ({
      ...initialState,
      accessToken: '',
      refreshToken: '',
      setupTime: '',
      user: null,
    }),
    setUser: (state, action: PayloadAction<IAuthState['user']>) => {
      state.user = action.payload;
    },
    setIsOpenRegisterPopup: (
      state,
      action: PayloadAction<IAuthState['isOpenRegisterPopup']>,
    ) => {
      state.isOpenRegisterPopup = action.payload;
    },
    setIsOpenLoginPopup: (
      state,
      action: PayloadAction<IAuthState['isOpenLoginPopup']>,
    ) => {
      state.isOpenLoginPopup = action.payload;
    },
    setIsOpenContactUsPopup: (
      state,
      action: PayloadAction<IAuthState['isOpenContactUsPopup']>,
    ) => {
      state.isOpenContactUsPopup = action.payload;
    },
    setIsOpenResetPasswordPopup: (
      state,
      action: PayloadAction<IAuthState['isOpenResetPasswordPopup']>,
    ) => {
      state.isOpenResetPasswordPopup = action.payload;
    },
    clearErrors: (state) => {
      state.login.error = '';
      state.register.error = '';
    },
  },
  extraReducers: (builder) =>
    builder
      /* Login */
      .addCase(loginThunk.pending, (state) => {
        state.login.isLoading = true;
      })
      .addCase(loginThunk.fulfilled, (state, { payload }) => {
        state.login.isLoading = false;
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        state.user = payload.user;
        state.isAuth = true;
      })
      .addCase(loginThunk.rejected, (state, { payload }) => {
        state.login.isLoading = false;
        state.login.error = payload as string;
        state.isAuth = false;
      })
      .addCase(logoutThunk.pending, (state) => {
        state.accessToken = '';
        state.refreshToken = '';
        state.user = null;
        state.isAuth = false;
      })

      /* User */
      .addCase(getUserInfoThunk.pending, (state) => {
        state.userIsLoading = true;
      })
      .addCase(getUserInfoThunk.fulfilled, (state) => {
        state.userIsLoading = false;
        state.isAuth = true;
      })
      .addCase(getUserInfoThunk.rejected, (state) => {
        state.userIsLoading = false;
        state.isAuth = false;
      })

      .addCase(updatePasswordThunk.rejected, (state, { payload }) => {
        state.errors = (payload as any).data;
      })
      .addCase(registerThunk.pending, (state) => {
        state.register.isLoading = true;
      })
      .addCase(registerThunk.fulfilled, (state) => {
        state.register.isLoading = false;
      })
      .addCase(registerThunk.rejected, (state, { payload }) => {
        state.register.isLoading = false;
        state.register.error = payload as string;
      }),
});

export default authSlice.reducer;
