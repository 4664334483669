import { styled } from '@mui/material/styles';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { FontFamilyNames } from '@appTypes/fonts';

import { COLORS } from '@utils/colors';

export const Content = styled(Stack)(({ theme }) => ({
  padding: '150px 40px 0 40px',

  [theme.breakpoints.down('sm')]: {
    padding: '60px 20px 20px 20px',
  },
}));

export const QuestionBlock = styled(Box)(({ theme }) => ({
  marginBottom: '40px',
  maxWidth: 870,

  [theme.breakpoints.down('md')]: {
    marginBottom: '15px',
  },
}));

export const QuestionsStack = styled(Stack)(() => ({
  width: '100%',
  alignItems: 'start',
}));

export const QuestionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '8px',
  fontWeight: '400',
  fontSize: '24px',
  color: COLORS.darkBlue,
  fontFamily: FontFamilyNames.MoretRegular,

  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    marginBottom: '15px',
  },
}));

export const QuestionDescription = styled(Typography)(({ theme }) => ({
  fontWeight: '300',
  fontSize: 16,
  lineHeight: '125%',
  color: COLORS.darkBlue,
  fontFamily: FontFamilyNames.PlainLight,

  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    marginBottom: '12px',
  },
}));

export const FaqContent = styled(Stack)(({ theme }) => ({
  padding: '100px 40px 0 40px',

  [theme.breakpoints.down('sm')]: {
    padding: '60px 20px 20px 20px',
  },
}));
