import { authSlice } from './auth.slice';

export const {
  clearErrors: clearErrorsAction,
  resetAuth: resetAuthAction,
  setIsOpenRegisterPopup: setIsOpenRegisterPopupAction,
  setIsOpenLoginPopup: setIsOpenLoginPopupAction,
  setUser: setUserAction,
  setIsOpenContactUsPopup: setIsOpenContactUsPopupAction,
  setIsOpenResetPasswordPopup: setIsOpenResetPasswordPopupAction,
} = authSlice.actions;
