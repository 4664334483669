import { BackStepEnums } from '@appTypes/enums/steps';
import { HouseModel } from '@appTypes/model/house.model';
import {
  AllMoveLastStep,
  LastStepDate,
  LastStepInsurance,
  LastStepLocation,
  LastStepPeople,
  LastStepRoom,
  LastStepSignature,
} from '@appTypes/model/lastStep.move';

import { OrderState } from './types';

type GetCurrecntStepMoveState = {
  state: OrderState;
  data: AllMoveLastStep;
};

export const getCurrentStepMoveState = ({
  state,
  data,
}: GetCurrecntStepMoveState) => {
  const stepType: BackStepEnums = data.step;

  const obj: Record<number, () => void> = {
    [BackStepEnums.INIT]: () => {
      // const { step } = data as LastStepInit;

      // state.activeStep = step + 1 as number;
    },
    [BackStepEnums.LOCATION]: () => {
      const {
        distance,
        toLocation,
        fromLocation,
        toCoordinates,
        fromCoordinates,
      } = data as LastStepLocation;

      // state.activeStep = step as number;
      state.location = {
        distance,
        fromLocation: {
          address: toLocation,
          latitude: `${fromCoordinates.x}`,
          longitude: `${fromCoordinates.y}`,
        },
        toLocation: {
          address: fromLocation,
          latitude: `${toCoordinates.x}`,
          longitude: `${toCoordinates.y}`,
        },
      };

      if (toLocation && fromLocation) {
        state.isDisableNextStep = true;
      }
    },
    [BackStepEnums.DATE]: () => {
      const { fromDate, toDate } = data as LastStepDate;

      state.fromDate = fromDate;
      state.toDate = toDate;
      // state.activeStep = step as number;

      if (fromDate && toDate && fromDate === toDate) {
        state.isExactDate = true;
      }

      if (fromDate && toDate) {
        state.isDisableNextStep = true;
      }
    },
    [BackStepEnums.HOUSE]: () => {
      // const { step } = data as LastStepHouse;

      // state.activeStep = step as number;
    },
    [BackStepEnums.ROOM]: () => {
      const { rooms } = data as LastStepRoom;

      // state.activeStep = step as number;

      const emptySelectedHoouse = {} as HouseModel;

      if (rooms.length > 0) {
        state.selectedHouse = {
          ...emptySelectedHoouse,
          houseRooms: rooms,
        };
      }
    },
    [BackStepEnums.INVENTORY]: () => {
      // const { step } = data as LastStepInventory;

      // state.activeStep = step as number;
    },
    [BackStepEnums.PEOPLE]: () => {
      const { countPeople, includeProKitchenPackage } =
        data as LastStepPeople;

      // state.activeStep = step + 1;
      state.countPeople = countPeople;
      state.proKitchen = includeProKitchenPackage;
    },
    [BackStepEnums.INSURANCE]: () => {
      const { insuranceId } = data as LastStepInsurance;

      // state.activeStep = step + 1;
      state.insuranceId = insuranceId;
    },
    [BackStepEnums.SIGN]: () => {
      const signatureData = data as LastStepSignature;

      // state.activeStep = signatureData.step;
      state.signature = signatureData;
    },
  };

  return obj[stepType]?.();
};
