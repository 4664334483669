import { styled, tooltipClasses } from '@mui/material';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { COLORS } from '@utils/colors';

export const CustomWidthTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 370,
  },
});

export const Triangle = styled('div')`
  width: 0;
  height: 0;
  position: relative;
  border-style: solid;
  border-width: 0 0px 30px 30px;
  border-color: transparent transparent white transparent;
`;

export const TooltipContent = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '18px',
  background: 'white',
  padding: '20px',
  fontSize: 15,
}));

export const SkipText = styled(Typography)(() => ({
  borderBottom: `2px solid ${COLORS.grey['50']}`,
  color: COLORS.grey['50'],
  cursor: 'pointer',
}));
