import { useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@components/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useRouter } from '@hooks/useRouter';
import { StyledMenuItem } from '../../styled';
import {
  setIsOpenContactUsPopupAction,
  setIsOpenLoginPopupAction, setIsOpenRegisterPopupAction,
} from '@store/features/auth/auth.actions';
import { logoutThunk } from '@store/features/auth/auth.thunks';
import UserInfo from './UserInfo';
import { setIsOpenSaveProgressPopupOpenAction } from '@store/features/order/order.actions';
import { CustomWidthTooltip, SkipText, TooltipContent, Triangle } from './styled';

const dontShowTooltip = ['/date', '/people', '/house'];

const AuthActions = () => {
  const dispatch = useAppDispatch();

  const {
    location: { pathname },
    navigate,
  } = useRouter();

  const user = useAppSelector(({ auth }) => auth.user);

  const handleLogout = () => {
    dispatch(logoutThunk()).unwrap().then(() => {
      // navigate(PAGE_ROUTES_PRIVATE.HOME);
    });

    // TODO: Maybe can need in future, test then remove
    // if (pathname === '/my-profile' || pathname === '/quote') {
    //   navigate(PAGE_ROUTES_PRIVATE.HOME);
    // }
  };

  const isHomePage = pathname === PAGE_ROUTES_PRIVATE.HOME;

  const [saveProgressTooltipOpen, setSaveProgressTooltipOpen] = useState(false);
  const [saveProgressSkipped, setSaveProgressSkipped] = useState(false);

  const handleSaveProgress = () => {
    if (dontShowTooltip.includes(pathname)) {
      dispatch(setIsOpenSaveProgressPopupOpenAction(true));

      return;
    }

    setSaveProgressTooltipOpen((state) => !state);
  };

  const handleSaveProgressSkip = () => {
    setSaveProgressTooltipOpen(false);
    setSaveProgressSkipped(true);
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (!dontShowTooltip.includes(pathname) && pathname !== '/quote' && !saveProgressSkipped) {
      timeout = setTimeout(() => {
        setSaveProgressTooltipOpen(true);
      }, 1000);
    }

    return () => {
      setSaveProgressTooltipOpen(false);
      clearTimeout(timeout);
    };
  }, [pathname, saveProgressSkipped]);

  return (
    <Stack
      direction="row"
      spacing={{ lg: '45px', sm: 2, md: '25px' }}
      alignItems="center"
      justifyContent="flex-end"
      minWidth="340px"
    >
      {(isHomePage && !user) && (
        <Button
          sx={{
            padding: '12px 30px',
            maxHeight: '44px',
          }}
          onClick={() => navigate('/trucker-landing')}
        >
          Become A Partner
        </Button>
      )}

      {(user) ? (
        <>
          <UserInfo />
          <StyledMenuItem
            onClick={() => dispatch(setIsOpenContactUsPopupAction(true))}
          >
            Contact us
          </StyledMenuItem>
          <StyledMenuItem mr="35px" onClick={handleLogout}>
            Log Out
          </StyledMenuItem>
        </>
      ) : (
        <>
          <StyledMenuItem
            onClick={() => dispatch(setIsOpenLoginPopupAction(true))}
          >
            Login
          </StyledMenuItem>
          {isHomePage ? (
            <StyledMenuItem
              onClick={() => {
                dispatch(setIsOpenRegisterPopupAction(true));
              }}
            >
              Create account
            </StyledMenuItem>
          ) : (
            <CustomWidthTooltip
              placement="bottom-start"
              title={(
                <Box>
                  <Box display="flex" justifyContent="end" paddingRight="50px">
                    <Triangle />
                  </Box>

                  <TooltipContent>
                    You are only 2 steps away from getting your quote! Do you want to save your progress?

                    <Box display="flex" justifyContent="space-between" marginTop={5}>
                      <SkipText
                        onClick={handleSaveProgressSkip}
                      >
                        Skip
                      </SkipText>
                      <Button
                        onClick={() => {
                          setSaveProgressTooltipOpen(false);
                          dispatch(setIsOpenSaveProgressPopupOpenAction(true));
                        }}
                      >
                        Yes
                      </Button>
                    </Box>
                  </TooltipContent>
                </Box>

              )}
              open={saveProgressTooltipOpen}
              PopperProps={{
                popperOptions: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 30],
                      },
                    },
                  ],
                },
              }}
            >
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                onClick={handleSaveProgress}
              >
                Save Progress
              </Typography>
            </CustomWidthTooltip>
          )}
          <StyledMenuItem
            onClick={() => dispatch(setIsOpenContactUsPopupAction(true))}
          >
            Contact us
          </StyledMenuItem>
        </>
      )}
    </Stack>
  );
};

export default AuthActions;
