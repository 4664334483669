import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@components/Typography';

export const StyledDrawerContent = styled(Box)(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  padding: 20,
}));

export const StyledMenuBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
}));

export const StyledBottomLinksStack = styled(Stack)(() => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',

  [`& ${StyledBottomLink}`]: {
    marginTop: 7,
  },

  [`& ${StyledBottomLink}:nth-child(1)`]: {
    flex: '0 100%',
  },
}));

export const StyledBottomLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey['50'],
}));
