import Typography from '@components/Typography';
import { styled } from '@mui/material/styles';

import { COLORS } from '@utils/colors';

export const StyledErrorMessage = styled(Typography)(() => ({
  color: COLORS.error.main,
  fontSize: '12px',
}));

export const StyledSuccessMessage = styled(Typography)(() => ({
  color: COLORS.morningGlory,
  fontSize: '18px',
}));
