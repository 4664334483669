import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PlainImage from '@components/Image/PlainImage';
import Typography from '@components/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { FontFamilyNames } from '@appTypes/fonts';

import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';
import aboutUsImage from '@assets/svg/moobile-about-us.svg';
import mooLogo from '@assets/svg/moo-logo.svg';
import {
  RightPart,
  StyledModal,
  StyledIconButton,
  StyledDialogContent,
  ImageBox,
  LeftStack,
} from '@pages/AboutUs/styled';

interface TruckerAboutUsProps {
  isOpen: boolean;
  onClose: () => void;
}

const TruckerAboutUs = ({ isOpen, onClose }: TruckerAboutUsProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledModal open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ padding: 0 }}>
        <StyledIconButton aria-label="close" onClick={onClose}>
          {matches ? <MobileCloseIcon /> : <CloseIcon />}
        </StyledIconButton>
      </DialogTitle>

      {matches ? (
        <Box>
          <PlainImage objectFit="cover" src={aboutUsImage} />
        </Box>
      ) : (
        <DialogTitle sx={{ padding: 0 }}>
          <StyledIconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </StyledIconButton>
        </DialogTitle>
      )}

      <StyledDialogContent>
        <LeftStack spacing="30px">
          {!matches && (
            <Typography
              component="h3"
              variant="h1"
              label="About Us"
              letterSpacing="0"
            />
          )}

          <Stack spacing="10px">
            <Typography
              component="h5"
              variant="h5"
              label="Who Are We?"
              fontFamily={FontFamilyNames.MoretBook}
              letterSpacing="0"
            />
            <Typography component="p" variant="body1">
              Moo Moves You is the first Digital Moving Marketplace.
              Our Mission is to simplify and standardize the moving
              process by combining easy to use technology and our online
              marketplace to match you, our customer, to our pre-qualified
              market of nationwide moving companies.
            </Typography>
            <br />
            <Typography component="p" variant="body1">
              Don’t spend money buying leads that may or may not close.
              Join Moo Moves You and spend less money buying confirmed jobs
              off of our Digital Load Board! Only accept the jobs at prices
              you want and on dates and pickup locations that work for your trucks routing!
            </Typography>
          </Stack>

          <Stack spacing="10px">
            <Typography
              component="h5"
              variant="h5"
              label="What Do We Do?"
              fontFamily={FontFamilyNames.MoretBook}
              letterSpacing="0"
            />
            <Typography component="p" variant="body1">
              We provide binding estimates to our clients based on the information
              they provide and the inventory they enter into our inventory calculator.
              After they confirm their job by making a deposit, this job becomes available
              on our Digital Load Board for you to accept! Once the job is accepted, you
              receive your customers contact information along with a detailed inventory
              list so your customer onboarding process is clean and simple!
            </Typography>
          </Stack>

          <Stack spacing="10px">
            <Typography
              component="h5"
              variant="h5"
              label="Why Work With Moo?"
              fontFamily={FontFamilyNames.MoretBook}
              letterSpacing="0"
            />
            <Typography
              component="p"
              variant="body1"
              label="Our goal is to make your move udderly smooth!"
            />
            <Typography
              component="p"
              variant="body1"
            >
              Stop spending money buying leads that are being recycled and sold to you and all
              of your competitors! Decrease your customer service cost by eliminating the need
              for your reps to be on the phone for hours with prospective customers that are
              shopping their job around to several moving companies. Become a Moo Trucker
              Partner and buy confirmed, closed jobs off of our Digital Load Board! Spend
              less, make more!
            </Typography>
            <br />
            <Typography
              component="p"
              variant="body1"
              label="Let’s Get Moo’ving!"
            />
          </Stack>
        </LeftStack>

        {!matches && (
          <RightPart>
            <ImageBox>
              <PlainImage objectFit="cover" src={mooLogo} />
            </ImageBox>
          </RightPart>
        )}
      </StyledDialogContent>
    </StyledModal>
  );
};

export default TruckerAboutUs;
