import { styled } from '@mui/material/styles';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import MuiPhoneNumber from 'react-phone-input-2';
import Stack from '@mui/material/Stack';

import { StyledLoginButton } from '@pages/LoginMoal/styled';
import { COLORS } from '@utils/colors';

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: 10,
  fontWeight: 400,
  fontSize: 40,

  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
  },
}));

export const StyledDialogContent = styled(Stack)(() => ({
  padding: '0',
  borderRadius: 20,
  height: '100%',
  justifyContent: 'space-between',
}));

export const StyleContent = styled(Box)(() => ({
  padding: '60px 0 25px 0',
}));

export const StyledModalDescription = styled(Typography)(({ theme }) => ({
  fontSize: 19,
  textAlign: 'center',
  marginBottom: 48,

  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const StyledModalBottomText = styled(Typography)(({ theme }) => ({
  fontSize: 19,
  textAlign: 'center',
  marginTop: 40,

  span: {
    cursor: 'pointer',
    marginLeft: 5,
    textDecoration: 'underline',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
    marginTop: 20,
  },
}));

export const StyledRegisterBackground = styled(StyledLoginButton)(() => ({
  marginTop: 40,
}));

export const StyledLeftBackground = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledRightBackground = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledMobileBackground = styled('img')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const StyledPhoneNumber = styled(MuiPhoneNumber)(({ theme }) => ({
  width: '300px',
  borderBottom: `1px solid ${COLORS.darkBlue}`,
  borderRadius: 0,
  input: {
    textAlign: 'center',
    width: '100%',
    border: 'none',
    background: 'transparent',
    color: COLORS.darkBlue,
    padding: '17px 15px  15px 0',
    fontSize: '16px',
  },
  '.form-control::placeholder': {
    color: COLORS.darkBlue,
    opacity: 0.5,
  },
  '.form-control:focus-visible': {
    outlineWidth: 0,
  },
  '& .special-label': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    width: '256px',
    input: {
      fontSize: '14px',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
}));
