import { styled, Theme } from '@mui/material/styles';
import Modal from '@components/Modal';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@components/Typography';

import { COLORS } from '@utils/colors';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    maxWidth: '1080px',
    maxHeight: '100%',
    height: 'fit-content',
    overflowX: 'hidden',
    width: '1080px',

    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
  },

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
  padding: 0,
}));

export const ContentTop = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  padding: '108px 30px',

  '@media (max-height: 1020px)': {
    padding: '40px 30px',
  },

  [theme.breakpoints.down('sm')]: {
    alignItems: 'start',
  },
}));

export const BottomPart = styled(Box)(() => ({
  backgroundColor: COLORS.beige,
}));

export const BottomContainer = styled(Stack)(() => ({
  padding: '97px 30px',
  maxWidth: '850px',
  margin: '0 auto',
  '@media (max-height: 1020px)': {
    padding: '40px 30px',

  },
}));

export const StyledSteps = styled(Stack)(() => ({
  maxWidth: '350px',
  justifyContent: 'center',
}));

export const OrderedList = styled(Box)(() => ({
  paddingLeft: '20px',
  li: {
    marginTop: '21px',
  },
  a: {
    textDecoration: 'underline',
  },
}));

export const RightMoo = styled(Stack)(() => ({
  maxWidth: '165px',
  img: {
    width: '155px',
    height: '166px',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }: {theme: Theme}) => ({
  position: 'absolute',
  zIndex: 999,
  right: 70,
  top: 50,
  color: COLORS.darkBlue,

  svg: {
    fontSize: '68px',
  },

  [theme.breakpoints.down('md')]: {
    right: '0',
    top: '0',
    background: theme.palette.secondary.main,
    borderRadius: '0',
    border: `1px solid ${theme.palette.common.black}`,
    width: '51px',
    height: '49px',

    ':hover': {
      background: theme.palette.secondary.main,
    },
  },

}));

export const HowItWorkText = styled(Typography)(({ theme }: {theme: Theme}) => ({
  marginBottom: '25px',
  fontWeight: '400',
  letterSpacing: '-0.03px',

  [theme.breakpoints.down('md')]: {
    fontSize: '30px',
    marginBottom: 12,
  },
}));
