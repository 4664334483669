// import { useMemo } from 'react';

import Box from '@mui/material/Box';
// import Stack from '@mui/material/Stack';
import PlainImage from '@components/Image/PlainImage';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages';

import appLogo from '@assets/svg/app-logo.svg';
// import { FooterNumber } from '@pages/Home/styled';
// import { DOT, MC } from '@pages/Home';

const Center = () => {
  const navigate = useNavigate();
  // const { pathname } = useLocation();

  const handleLogoImageClick = () => {
    navigate(PAGE_ROUTES_PRIVATE.HOME);
  };

  // const isTruckerPage = useMemo(() => {
  //   return pathname.includes(PAGE_ROUTES_PRIVATE.TRUCKER_LANDING)
  //     || pathname.includes(PAGE_ROUTES_PRIVATE.TRUCKER_BECOME_PARTNER);
  // }, [pathname]);

  return (
    <Box
      sx={{
        cursor: 'pointer',
        width: '340px',
        img: {
          maxWidth: 210,
        },
      }}
    >
      <PlainImage onClick={handleLogoImageClick} objectFit="contain" src={appLogo} />

      {/* {isTruckerPage && (
        <Stack flexDirection="row" gap={1} justifyContent="center">
          <FooterNumber>{DOT}</FooterNumber>
          <FooterNumber>-</FooterNumber>
          <FooterNumber>{MC}</FooterNumber>
        </Stack>
      )} */}
    </Box>
  );
};

export default Center;
