export enum MoveStepsEnum {
  INIT = 0,
  LOCATION = 1,
  DATE = 2,
  PEOPLE = 3,
  HOUSE = 4,
  ROOM = 5,
  INVENTORY = 6,
  INVENTORY_REVIEW = 7,
  INSURANCE = 8,
}

// TODO: here must change logic  of step MoveStepsTest temprory solution

export enum BackStepEnums {
  INIT = 0,
  LOCATION = 1,
  DATE = 2,
  PEOPLE = 3,
  HOUSE = 4,
  ROOM = 5,
  INVENTORY = 6,
  INSURANCE = 7,
  SIGN = 8,
  TRANSACTION = 9,
}

export enum SignatureRequestStatusEnum {
  INPROGRESS = 'inprogress',
  COMPLETED = 'completed',
}

export enum DocumentActionStatuses {
  MANUALLY_SIGNED = 'MANUALLY_SIGNED',
  DECLINED = 'DECLINED',
  SIGNED = 'SIGNED',
  UNOPENED = 'UNOPENED',
}

export enum PaymentProvider {
  NATIVE = 'NATIVE',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
}
