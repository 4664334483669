import { ReactNode } from 'react';

import DesktopStepper from '@components/Stepper/DesktopStepper';
import clsx from 'clsx';
import AffiliateNavbar from '@components/AppBar/components/AffiliateNavbar';

import { AllRoutesType, PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages';

import { StyledLogo, StyledToolbar } from './styled';
import Center from './components/Center';
import TruckerNavbar from './components/TruckerNavbar';
import AuthActions from './components/AuthActions';
import ComingSoonLeft from './components/ComingSoonNavbar/LeftPart';
import ComingSoonRight from './components/ComingSoonNavbar/RightPart';
import { COLORS } from '@utils/colors';

export interface IAppBarProps {
  handleDrawerToggle: () => void;
}

export const renderAppBarContentByPahtname = ({
  pathname,
  handleDrawerToggle,
  isOpenRoomDrawer,
}: {
  pathname: AllRoutesType;
  isOpenRoomDrawer: boolean;
  handleDrawerToggle: IAppBarProps['handleDrawerToggle'];
}) => {
  const paths = pathname;

  const ElementsByPath: Partial<Record<AllRoutesType, ReactNode>> = {
    '/': (
      <StyledToolbar>
        <ComingSoonLeft handleDrawerToggle={handleDrawerToggle} />

        <Center />

        <ComingSoonRight />
      </StyledToolbar>
    ),
    [PAGE_ROUTES_PUBLIC.ABOUT]: (
      <StyledToolbar>
        <ComingSoonLeft handleDrawerToggle={handleDrawerToggle} />

        <Center />

        <ComingSoonRight />
      </StyledToolbar>
    ),
    [PAGE_ROUTES_PUBLIC.FAQ]: (
      <StyledToolbar>
        <ComingSoonLeft handleDrawerToggle={handleDrawerToggle} />

        <Center />

        <ComingSoonRight />
      </StyledToolbar>
    ),
    [PAGE_ROUTES_PUBLIC.HOW_IT_WORKS]: (
      <StyledToolbar>
        <ComingSoonLeft handleDrawerToggle={handleDrawerToggle} />

        <Center />

        <ComingSoonRight />
      </StyledToolbar>
    ),
    [PAGE_ROUTES_PUBLIC.AFFILIATE]: (
      <AffiliateNavbar
        sxProps={{
          background: COLORS.beige,
        }}
      />
    ),
    [PAGE_ROUTES_PUBLIC.AFFILIATE_BECOME_PARTNER]: (
      <AffiliateNavbar
        sxProps={{
          background: 'white',
        }}
      />
    ),
    // '/get-started': (
    //   <StyledToolbar>
    //     <LeftPart handleDrawerToggle={handleDrawerToggle} />
    //
    //     <Center />
    //
    //     <AuthActions />
    //   </StyledToolbar>
    // ),
    '/trucker-landing': (
      <StyledToolbar>
        <StyledLogo>
          <Center />
        </StyledLogo>

        <TruckerNavbar />
      </StyledToolbar>
    ),
    '/trucker-become-partner': (
      <StyledToolbar>
        <StyledLogo>
          <Center />
        </StyledLogo>

        <TruckerNavbar />
      </StyledToolbar>
    ),
    '/checkout': (
      <StyledToolbar className={clsx({ isCheckout: pathname === '/checkout' })}>
        <StyledLogo>
          <Center />
        </StyledLogo>

        <AuthActions />
      </StyledToolbar>
    ),
    '/my-profile': (
      <StyledToolbar>
        <StyledLogo>
          <Center />
        </StyledLogo>
        <AuthActions />
      </StyledToolbar>
    ),
  };

  const defaultElementsByPath = (
    <StyledToolbar>
      {!isOpenRoomDrawer && <Center />}

      <DesktopStepper />

      <AuthActions />
    </StyledToolbar>
  );

  return ElementsByPath[paths] || defaultElementsByPath;
};
