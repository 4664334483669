import * as yup from 'yup';

export const RegisterFormSchema = yup.object().shape({
  username: yup
    .string()
    .required(),

  email: yup
    .string()
    .email()
    .required(),

  password: yup
    .string()
    .required(),

  confirmPassword: yup.string().required('Confirm password is a required field')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),

  phone: yup
    .string()
    .required(),
});
