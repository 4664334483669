import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { COLORS } from '@utils/colors';
import { StyledButtonGetStarted } from '@pages/Home/styled';

export const StyledStack = styled(Stack)(() => ({
  flexDirection: 'column',
}));

export const StyledLink = styled(Typography)<{ disabled?: boolean }>(({ disabled }) => ({
  color: COLORS.darkBlue,
  cursor: 'pointer',
  borderBottom: `1px solid ${COLORS.grey['50']}`,
  padding: '20px 0',
  width: '100%',
  display: 'flex',
  alignItems: 'center',

  svg: {
    marginRight: 5,
  },

  ...(disabled && {
    opacity: 0.5,
  }),
}));

export const StyledGetStartedButton = styled(StyledButtonGetStarted)(() => ({
  padding: '11px 39px',
  width: 162,
  fontSize: 20,
}));

export const BecomePartnerButton = styled(StyledGetStartedButton)(() => ({
  width: 200,

  '&.MuiButton-root': {
    margin: '15px 0',
  },
}));
