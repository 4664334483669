import { AxiosResponse } from 'axios';
import { http } from 'api/RequestApi';

import { IUserModel } from '@appTypes/model/user.model';

import {
  ILoginResponse,
  ILoginPayload,
  IUpdatePassword,
  IRegisterPayload,
  IRefreshTokenDto, IResetPasswordPayload, ISetNewPasswordPayload, IUpdateProfile,
} from '@store/features/auth/auth.types';

class AuthService {
  static prefix = '/auth';

  static async login(data: ILoginPayload) {
    return http.post<ILoginPayload, AxiosResponse<ILoginResponse>>(
      `${this.prefix}/signIn`,
      data,
    );
  }

  static async register(data: IRegisterPayload) {
    return http.post<IRegisterPayload, AxiosResponse<ILoginResponse>>(
      `${this.prefix}/signUp`,
      data,
    );
  }

  static async getProfile() {
    return http.get<void, AxiosResponse<IUserModel>>(`${this.prefix}/profile`);
  }

  static async logout() {
    return http.post(`${this.prefix}/signOut`);
  }

  static async updateProfile(data: IUpdateProfile) {
    return http.put(`${this.prefix}/profile`, data);
  }

  static async changePassword(data: IUpdatePassword) {
    return http.put(`${this.prefix}/password`, data);
  }

  static async resetPassword(data: IResetPasswordPayload) {
    return http.post(`${this.prefix}/resetPassword`, { ...data, role: 'web' });
  }

  static async setNewPassword(data: ISetNewPasswordPayload) {
    return http.put(`${this.prefix}/setPassword`, data);
  }

  static async refreshToken(data: IRefreshTokenDto) {
    return http.post<IRefreshTokenDto, AxiosResponse<ILoginResponse>>(`${this.prefix}/refresh`, data);
  }
}

export default AuthService;
