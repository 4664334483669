import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { COLORS } from '@utils/colors';

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  flexGrow: 1,
  width: '100%',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  minHeight: '100vh',
  padding: '0 100px',
  backgroundColor: COLORS.beige,

  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
  },
}));

export const StyledOutletContainer = styled(Box)`
  &.home {
    max-width: 1436px;
  }
`;

export const StyledToolbar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '36.5px',
  paddingRight: '36.5px',
  background: COLORS.beige,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
