import Stack from '@mui/material/Stack';
import PlainImage from '@components/Image/PlainImage';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { FontFamilyNames } from '@appTypes/fonts';

import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import mooLogo from '@assets/svg/trucker-how-it-works.svg';
import systemSign from '@assets/svg/system-sign.svg';
import {
  BottomPart,
  ContentTop,
  StyledModal,
  StyledSteps,
  OrderedList,
  HowItWorkText,
  BottomContainer,
  StyledIconButton,
  StyledDialogContent,
} from '@pages/HowItWorks/styled';
import { COLORS } from '@utils/colors';

interface TruckerHowItWorksPopupProps{
  isOpen: boolean;
  onClose: () => void;
}

const TruckerHowItWorksPopup = ({ isOpen, onClose }: TruckerHowItWorksPopupProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledModal open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ padding: 0 }}>
        <StyledIconButton
          aria-label="close"
          onTouchMove={onClose}
          onClick={onClose}
        >
          {matches ? <MobileCloseIcon /> : <CloseIcon />}
        </StyledIconButton>
      </DialogTitle>

      <StyledDialogContent>
        <ContentTop>
          <HowItWorkText
            component="h3"
            variant="h1"
            label="How Does it Work?"
          />
          <Typography
            component="p"
            variant="body1"
            label="Three Easy Steps to Claim Your Jobs!"
            fontSize="24px"
          />

          <Stack direction="row" pt={matches ? '20px' : '40px'}>
            <StyledSteps spacing={matches ? '0' : '25px'}>
              <Stack alignItems="center" direction="row" spacing="20px">
                <Typography
                  component="h3"
                  variant="h1"
                  label="1"
                  color={COLORS.grey[50]}
                  margin={matches ? '0 3.5px' : '0 7px'}
                />
                <Typography
                  component="p"
                  variant="body1"
                  label="Go to our Digital Load Board which you have access to through your Trucker Login."
                  fontSize="20px"
                />
              </Stack>

              <Stack alignItems="center" direction="row" spacing="20px">
                <Typography
                  component="h3"
                  variant="h1"
                  label="2"
                  color={COLORS.yellowOG}
                />
                <Typography
                  component="p"
                  variant="body1"
                  label="Pick the jobs you want based on date, origin, destination, price and cubic feet."
                  fontSize="20px"
                />
              </Stack>

              <Stack alignItems="center" direction="row" spacing="20px">
                <Typography
                  component="h3"
                  variant="h1"
                  label="3"
                  color={COLORS.orange}
                />
                <Typography
                  component="p"
                  variant="body1"
                  label="Download all of your customer information and inventory list and onboard them just like
                      every other customer of yours!"
                  fontSize="20px"
                />
              </Stack>
            </StyledSteps>

            {!matches && (
              <>
                <Box sx={{ margin: '0 31px 0 11px' }} width="50px">
                  <PlainImage
                    style={{ width: '50px' }}
                    objectFit="cover"
                    src={systemSign}
                  />
                </Box>

                <Box>
                  <img alt="moo" src={mooLogo} />
                  <Typography
                    component="p"
                    variant="body1"
                    label="Claim your Job and Get Moo’ving!"
                    fontSize="20px"
                    textAlign="center"
                    marginTop={3}
                  />
                </Box>
              </>
            )}
          </Stack>
        </ContentTop>
        <BottomPart>
          <BottomContainer
            direction={!matches ? 'row' : 'column'}
            spacing="50px"
          >
            <Stack spacing="20px" width={!matches ? '50%' : '100%'}>
              <Stack>
                <Typography
                  component="p"
                  variant="body1"
                  label="How Do I Apply?"
                  fontSize="24px"
                  fontFamily={FontFamilyNames.MoretRegular}
                  letterSpacing="0.04px"
                />
                <OrderedList component="ol">
                  <li>
                    Go to MooMovesYou.com , click “Become A Partner” and click “Apply Now”.
                  </li>
                  <li>
                    Fill out all of your company information into our online application and upload your
                    insurance policy.
                  </li>
                  <li>
                    Our approval process takes about 2-3 business days. Once approved, we will schedule
                    a quick meeting to meet your team and walk you through our entire process.
                  </li>
                  <li>
                    Sign our Broker / Carrier Agreement and receive your Moo Trucker Partner login
                    credentials.
                  </li>
                  <li>
                    Access our Digital Load Board and start claiming your jobs!
                  </li>
                </OrderedList>
              </Stack>
            </Stack>

            <Box width={!matches ? '50%' : '100%'}>
              <Typography
                component="p"
                variant="body1"
                label="How Does It Work?"
                fontSize="24px"
                fontFamily={FontFamilyNames.MoretRegular}
                letterSpacing="0.04px"
              />

              <OrderedList component="ol">
                <li>
                  After our clients sign their Job Agreement and make their deposit to confirm their binding
                  estimate, all of these jobs become available on our Digital Load Board.
                </li>
                <li>
                  All of our Moo Trucker Partners have access to all jobs and can claim jobs based on
                  pickup dates, origin / destination, price and cubic feet. Only pick the jobs that work for
                  your trucks routing.
                </li>
                <li>
                  After you claim a job, you receive all of your customers information including name, phone number,
                  email, full pickup and drop off addresses and a downloadable inventory
                  list to make the customer onboarding process as easy as possible
                </li>
                <li>
                  Your customer service team will be connected to your client via email and your team
                  takes it from there!
                </li>
                <li>
                  Stop spending money on leads, buy closed jobs!
                </li>
              </OrderedList>
            </Box>
          </BottomContainer>
        </BottomPart>
      </StyledDialogContent>
    </StyledModal>
  );
};

export default TruckerHowItWorksPopup;
