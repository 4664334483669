import { MoveStepsEnum } from '@appTypes/enums/steps';

export const STEPPER_HEIGHT = 25;

export const getStepTitle = (activeStep: MoveStepsEnum, pathname?: string) => {
  const inventoryReviewTitle = pathname === '/inventory-review' ? 'Inventory Review' : '';
  const quotePageTitle = pathname === '/quote' ? 'Quote' : '';
  const insurancePageTitle = pathname === '/insurance' ? 'Insurance' : '';
  const peoplePackageTitle = pathname === '/people' ? 'People' : '';

  if (inventoryReviewTitle) {
    return inventoryReviewTitle;
  }

  if (insurancePageTitle) {
    return insurancePageTitle;
  }

  if (quotePageTitle) {
    return quotePageTitle;
  }

  if (peoplePackageTitle) {
    return peoplePackageTitle;
  }

  switch (activeStep) {
    case MoveStepsEnum.LOCATION:
      return 'Move Address';
    case MoveStepsEnum.DATE:
      return 'Moving Dates';
    case MoveStepsEnum.HOUSE:
      return 'House Type';
    case MoveStepsEnum.ROOM:
      return 'Rooms in House';
    case MoveStepsEnum.INVENTORY:
      return 'Inventory By Room';
    case MoveStepsEnum.PEOPLE:
      return 'Moving Boxes';
    case MoveStepsEnum.INSURANCE:
      return 'Insurance';
      // case MoveStepsEnum.SIGN:
      //   return 'Signature';

    default:
      null;
  }
};
