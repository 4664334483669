import { memo } from 'react';

import MuiDrawer from '@mui/material/Drawer';
import MobileDrawerMenu from '@components/MobileDrawer/components/MobileDrawerMenu';
import Stack from '@mui/material/Stack';
import { BurgerMenuButton } from '@components/AppBar/styled';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages';

import { StyledBottomLink, StyledBottomLinksStack, StyledDrawerContent, StyledMenuBox } from './styled';
import { ReactComponent as MobileCloseMenuIcon } from '@assets/svg/mobile-close-icon.svg';
import { COLORS } from '@utils/colors';

interface IDrawerProps {
  drawerWidth: number | string;
  open: boolean;
  handleDrawerClose: () => void;
}

const MobileDrawer = ({
  drawerWidth,
  open,
  handleDrawerClose,
}: IDrawerProps) => {
  const navigate = useNavigate();

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <MuiDrawer
      open={open}
      anchor="right"
      variant="temporary"
      container={container}
      onClose={handleDrawerClose}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Stack alignItems="flex-end">
        <BurgerMenuButton
          disableFocusRipple
          onClick={handleDrawerClose}
          sx={{
            background: COLORS.beige,
          }}
        >
          <MobileCloseMenuIcon />
        </BurgerMenuButton>
      </Stack>
      <StyledDrawerContent>

        <StyledMenuBox>
          <MobileDrawerMenu onClose={handleDrawerClose} />
        </StyledMenuBox>

        <StyledBottomLinksStack>
          <StyledBottomLink
            label="Terms of Use"
            onClick={() => {
              navigate(PAGE_ROUTES_PUBLIC.TERMS_AND_CONDITIONS);
              handleDrawerClose();
            }}
          />
          <StyledBottomLink
            label="Privacy Policy"
            onClick={() => {
              navigate(PAGE_ROUTES_PUBLIC.PRIVACY_POLICY);
              handleDrawerClose();
            }}
          />
          <StyledBottomLink
            label="Cookie policy"
            onClick={() => {
              navigate(PAGE_ROUTES_PUBLIC.COOKIES_POLICY);
              handleDrawerClose();
            }}
          />
          <StyledBottomLink label="(C) 2023 Moo Moves You" />
        </StyledBottomLinksStack>

      </StyledDrawerContent>
    </MuiDrawer>
  );
};

export default memo(MobileDrawer);
