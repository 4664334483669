import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@components/Button';

export const Links = styled(Box)(() => ({
  display: 'grid',
  gridAutoFlow: 'column',
  gridGap: '37px',
  alignItems: 'center',
}));

export const SignUpButton = styled(Button)(() => ({
  padding: '12px 15px',
  fontSize: '16px',
  width: '102px',
  height: '42px',
}));
