import { http } from 'api/RequestApi';
import { AxiosResponse } from 'axios';

import { IPagination } from '@appTypes/global';
import { HouseModel, HouseModelItems } from '@appTypes/model/house.model';
import { MoveCreateHouseModel } from '@appTypes/model/create.move.model';

class HouseService {
  static prefix = '/houses';

  static async getAll(params?: IPagination) {
    const response = await http.get<void, AxiosResponse<HouseModelItems>>(
      `${this.prefix}`,
      { params },
    );

    return response.data;
  }

  static async createHouse(data: MoveCreateHouseModel) {
    const response = await http.post<MoveCreateHouseModel>(
      `${this.prefix}`,
      data,
    );

    return response;
  }

  static async getOne(houseId: string) {
    const response = await http.get<HouseModel>(
      `${this.prefix}/${houseId}/info`,
    );

    return response.data;
  }
}

export default HouseService;
