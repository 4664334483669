import * as yup from 'yup';

export const ContactUsFormSchema = yup.object().shape({
  name: yup
    .string()
    .required(),

  email: yup
    .string()
    .email()
    .required(),

  phone: yup
    .string()
    .required(),

  message: yup
    .string()
    .required(),
});
