import { styled, Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import { COLORS } from '@utils/colors';

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '36.5px',
  paddingRight: '36.5px',
  background: COLORS.beige,

  '&.isCheckout': {
    background: 'transparent',
  },

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const BurgerMenuButton = styled(IconButton)(
  ({ theme }: { theme: Theme }) => ({
    width: '51px',
    height: '51px',
    background: theme.palette.common.white,
    borderRadius: 'unset',
    border: `1px solid ${theme.palette.common.black}`,

    '&:hover': {
      background: theme.palette.common.white,
    },
  }),
);

export const StyledLogo = styled(Toolbar)(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: '36.5px',
  paddingRight: '36.5px',

  [theme.breakpoints.down('sm')]: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}));

export const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'drawerWidth' && prop !== 'trigger',
})<{ drawerWidth: number | string; open: boolean; trigger: boolean }>(
  ({ theme, open, drawerWidth }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),

    '&.isCheckout, &.matches': {
      background: 'transparent',
    },

    '.MuiTypography-root': {
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
  }),
);

export const StyledMenuItem = styled(Typography)<{ disabled?: boolean }>(({ disabled }) => ({
  cursor: 'pointer',

  ...(disabled && {
    opacity: 0.5,
  }),
}));

export const StyledMobileStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 20,

  img: {
    width: 130,
  },
}));

export const StyledMobileLogo = styled(Stack)(() => ({
  flexDirection: 'row',
}));
