import { styled } from '@mui/material/styles';
import Typography from '@components/Typography';

import { COLORS } from '@utils/colors';

export const StyledLink = styled(Typography)(() => ({
  color: COLORS.darkBlue,
  cursor: 'pointer',
  padding: '20px 0',
}));
