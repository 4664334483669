import { memo } from 'react';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import { StyledMenuItem } from '@components/AppBar/styled';
import { IAppBarProps } from '@components/AppBar/helper';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages';

const LeftPart = ({ handleDrawerToggle }: IAppBarProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Stack>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <Stack spacing={{ lg: '45px', sm: 2, md: '25px' }} direction="row">
          <StyledMenuItem
            onClick={() => navigate(PAGE_ROUTES_PUBLIC.ABOUT)}
          >
            About
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => navigate(PAGE_ROUTES_PUBLIC.HOW_IT_WORKS)}
          >
            How MOO works
          </StyledMenuItem>
          <StyledMenuItem onClick={() => navigate(PAGE_ROUTES_PUBLIC.FAQ)}>
            FAQs
          </StyledMenuItem>
        </Stack>
      </Stack>

    </>
  );
};

export default memo(LeftPart);
