import Stack from '@mui/material/Stack';
import Typography from '@components/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as UserIcon } from '@assets/svg/user-icon.svg';
import { useRouter } from '@hooks/useRouter';
import { useAppSelector } from '@store/hooks';

const UserInfo = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('l'));
  const user = useAppSelector(({ auth }) => auth.user);

  const { navigate } = useRouter();

  if (!user) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="5px"
      onClick={() => navigate('/my-profile')}
      sx={{ cursor: 'pointer' }}
    >
      {!matches ? (
        <>
          <UserIcon />
          <Typography>{user ? user.email : ''}</Typography>
        </>
      ) : (
        <Tooltip title={user.email}>
          <IconButton>
            <UserIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default UserInfo;
