import { styled } from '@mui/material/styles';
import Modal from '@components/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@components/Button';

import { FontFamilyNames } from '@appTypes/fonts';

import { COLORS } from '@utils/colors';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '100%',
    maxWidth: 670,
    borderRadius: 20,
  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,

    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%',
    },
  },
}));

export const StyledDialogContent = styled(Box)(() => ({
  padding: '100px 0 80px 0',
  borderRadius: 20,
}));

export const StyledModalTitle = styled(Typography)(() => ({
  marginBottom: 45,
  fontWeight: 400,
  fontSize: 40,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: 300,
  marginBottom: 10,

  '& .MuiOutlinedInput-root': {
    borderBottom: `1px solid ${COLORS.darkBlue}`,
    borderRadius: 0,
  },
  '& .MuiInputBase-input': {
    color: COLORS.darkBlue,
    textAlign: 'center',
    fontSize: 16,
    padding: '17px 15px 15px',
    letterSpacing: '-0.02em',
  },

  '& ::placeholder': {
    color: COLORS.darkBlue,
    opacity: 0.5,
  },

  'input:-webkit-autofill': {
    boxShadow: '0 0 0 1000px white inset !important',
  },

  [theme.breakpoints.down('sm')]: {
    width: 'unset',
    '& .MuiOutlinedInput-root': {
      width: '256px',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
}));

export const StyledLoginButton = styled(Button)(({ theme }) => ({
  marginTop: 80,
  width: 158,
  fontSize: '24px',
  padding: '4px 30px',
  fontFamily: FontFamilyNames.MoretRegular,

  [theme.breakpoints.down('sm')]: {
    marginTop: 44,
    padding: '9px 45px',
    width: 'unset',
    height: 36,
    fontSize: 16,
  },
}));

export const StyledLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,

  [theme.breakpoints.down('sm')]: {
    left: '50%',
    transform: 'translateX(-50%)',
    maxHeight: 190,
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18,
  },

}));

export const ResetPasswordLink = styled(Typography)`
  text-align: right;
  text-decoration: none;
  margin-top: 15px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  z-index: 3;
`;
