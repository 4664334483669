import { Components, css } from '@mui/material/styles';

import { FontFamilyNames } from '@appTypes/fonts';

import { resetCSS } from '@styles/reset';
import MoretFont from '@assets/fonts/Moret';
import PlainFont from '@assets/fonts/Plain';
import { COLORS } from '@utils/colors';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: css`
    ${resetCSS}

    ${MoretFont.fontFaces}
    ${PlainFont.fontFaces}


    :root {
      --toastify-font-family: ${FontFamilyNames.MoretRegular};
    }

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      outline: none;
    }

    body {
      webkit-font-smoothing: antialiased;
      moz-osx-font-smoothing: grayscale;
      line-height: unset;
      background:  ${COLORS.beige};
    }

    body,
    html,
    #root {
      min-height: var(--100vh);
      display: flex;
      flex-direction: column;
      scroll-behavior: smooth;
    }

    #root {
      color:  ${COLORS.darkBlue};
      
    }

    main {
      flex: 1;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    footer {
      z-index: 1;
    }

    .Toastify__toast-body {
      font-size: 14px;
    }

    .MuiTooltip-popper[data-popper-placement*="bottom"],
    .MuiTooltip-popper[data-popper-placement*="top"] {
      .MuiTooltip-tooltip {
        &.MuiTooltip-tooltip {  
          color: ${COLORS.black};
          background: none;
          margin-top: 0;
          margin-bottom: 9px;
          font-size: 13px;
        }
      }
    }
  ` as any,
};
