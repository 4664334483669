import { useState } from 'react';

import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import MobileDrawer from '@components/MobileDrawer';
import clsx from 'clsx';
import LogoAndBurgerMenu from '@components/AppBar/components/LogoAndBurgerMenu';
import { StyledAppBar, StyledLogo } from '@components/AppBar/styled';
import Center from '@components/AppBar/components/Center';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import { useRouter } from '@hooks/useRouter';
import { Main, StyledOutletContainer, StyledToolbar } from './styled';
import { MOBILE_DRAWER_WIDTH } from '@utils/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import LoginModal from '@pages/LoginMoal';
import Register from '@pages/Register';
import {
  clearErrorsAction, setIsOpenContactUsPopupAction,
  setIsOpenLoginPopupAction,
  setIsOpenRegisterPopupAction, setIsOpenResetPasswordPopupAction,
} from '@store/features/auth/auth.actions';
import ResetPasswordPopup from '@pages/ResetPasswordPopup';
import ContactUsPopup from '@pages/ContactUsPopup';
import TruckerFaqPopup from '@pages/TruckerFaqPopup';
import TruckerAboutUs from '@pages/TruckerAboutUs';
import TruckerHowItWorksPopup from '@pages/TruckerHowItWorksPopup';
import {
  setIsTruckerAboutPopupOpen,
  setIsTruckerFaqPopupOpen,
  setIsTruckerHowItWorksPopupOpen,
} from '@store/features/app/app.slice';

const Layout = () => {
  const { location: { pathname } } = useRouter();

  const [mobileDrawerIsOpen, setMobileDrawerIsOpen] = useState(false);

  const roomDrawerWidth = useAppSelector(({ order }) => order.roomDrawerWidth);
  const isOpenRoomDrawer = useAppSelector(
    ({ order }) => order.isOpenRoomDrawer,
  );

  const dispatch = useAppDispatch();

  const { isOpenLoginPopup, isOpenRegisterPopup, isOpenContactUsPopup, isOpenResetPasswordPopup } =
      useAppSelector((state) => state.auth);

  const { isTruckerFaqPopupOpen, isTruckerAboutPopupOpen,
    isTruckerHowItWorksPopupOpen,
  } = useAppSelector(
    (state) => state.app,
  );

  const handleLoginModalClose = () => {
    dispatch(clearErrorsAction());
    dispatch(setIsOpenLoginPopupAction(false));
  };

  const handleRegisterModalClose = () => {
    dispatch(clearErrorsAction());
    dispatch(setIsOpenRegisterPopupAction(false));
  };

  const handleContactUsModalClose = () => {
    dispatch(setIsOpenContactUsPopupAction(false));
  };

  const handleTruckerFaqPopupClose = () => {
    dispatch(setIsTruckerFaqPopupOpen(false));
  };

  const handleTruckerAboutPopupClose = () => {
    dispatch(setIsTruckerAboutPopupOpen(false));
  };

  const handleResetPasswordPopupClose = () => {
    dispatch(setIsOpenResetPasswordPopupAction(false));
  };

  const handleTruckerHowItWorksPopupClose = () => {
    dispatch(setIsTruckerHowItWorksPopupOpen(false));
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const pathClassName = pathname.split('/').filter(Boolean)[0];

  const handleDrawerToggle = () => {
    setMobileDrawerIsOpen(!mobileDrawerIsOpen);
  };

  const handleDrawerClose = () => {
    setMobileDrawerIsOpen(false);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <Box>
      {matches ? (
        <StyledAppBar
          open={isOpenRoomDrawer}
          drawerWidth={roomDrawerWidth}
          trigger={trigger}
          elevation={0}
          position="fixed"
          className={clsx({ matches })}
        >
          <LogoAndBurgerMenu handleDrawerToggle={handleDrawerToggle} />
        </StyledAppBar>
      ) : (
        <StyledToolbar className={clsx({ isCheckout: false })}>
          <StyledLogo>
            <Center />
          </StyledLogo>
        </StyledToolbar>
      )}

      <MobileDrawer
        open={mobileDrawerIsOpen}
        drawerWidth={MOBILE_DRAWER_WIDTH}
        handleDrawerClose={handleDrawerClose}
      />

      <Main open={mobileDrawerIsOpen}>
        <StyledOutletContainer className={pathClassName}>
          <Outlet />

          <LoginModal
            isOpen={isOpenLoginPopup}
            handleClose={handleLoginModalClose}
          />
          <Register
            isOpen={isOpenRegisterPopup}
            handleClose={handleRegisterModalClose}
            onLogin={() => {
              dispatch(setIsOpenRegisterPopupAction(false));
              dispatch(setIsOpenLoginPopupAction(true));
            }}
          />
          <ResetPasswordPopup
            isOpen={isOpenResetPasswordPopup}
            handleClose={handleResetPasswordPopupClose}
          />
          <ContactUsPopup
            isOpen={isOpenContactUsPopup}
            onClose={handleContactUsModalClose}
          />
          {/* <FaqPopup isOpen={isFaqPopupOpen} onClose={handleFaqPopupClose} /> */}
          <TruckerFaqPopup
            isOpen={isTruckerFaqPopupOpen}
            onClose={handleTruckerFaqPopupClose}
          />
          <TruckerAboutUs
            isOpen={isTruckerAboutPopupOpen}
            onClose={handleTruckerAboutPopupClose}
          />
          <TruckerHowItWorksPopup
            isOpen={isTruckerHowItWorksPopupOpen}
            onClose={handleTruckerHowItWorksPopupClose}
          />
        </StyledOutletContainer>
      </Main>
    </Box>
  );
};

export default Layout;
