import { orderSlice } from './order.slice';

export const {
  setDate: setDateAction,
  setAllRooms: setAllRoomsAction,
  setRoomItem: setRoomItemAction,
  setLocation: setLocationAction,
  setExactDate: setExactDateAction,
  setHoueRooms: setHoueRoomsAction,
  setActiveStep: setActiveStepAction,
  setToken: setTokenAction,
  setResetMove: setResetMoveAction,
  setCardId: setCardIdAction,
  setProKitchen: setProKitchenAction,
  setInsuranceId: setInsuranceIdAction,
  setIsSavedInfo: setIsSavedInfoAction,
  setCountPeople: setCountPeopleAction,
  selectedRoomIds: selectedRoomIdsAction,
  setSelectedRooms: setSelectedRoomsAction,
  setSelectedHouse: setSelectedHouseAction,
  setSelectedRoomId: setSelectedRoomIdAction,
  setRoomDrawerWidth: setRoomDrawerWidthAction,
  setRoomInventories: setRoomInventoriesAction,
  setIsSavedQuoteInfo: setIsSavedQuoteInfoAction,
  setIsOpenRoomDrawer: setIsOpenRoomDrawerAction,
  setIsDisableNextStep: setIsDisableNextStepAction,
  setIsConfirmedInventoryReview: setIsConfirmedInventoryReviewAction,
  setIsOpenInventoryReviewPopup: setIsOpenInventoryReviewPopupAction,
  setIsOpenSaveProgressPopupOpen: setIsOpenSaveProgressPopupOpenAction,
  setIsOpenSameStatePopup: setIsOpenSameStatePopupAction,
  setHash: setHashAction,
} = orderSlice.actions;
