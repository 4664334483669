import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { COLORS } from '@utils/colors';

export const StyledButton = styled(Button)(() => ({
  border: `1px solid ${COLORS.darkBlue}`,
  color: COLORS.darkBlue,
  textTransform: 'initial',

  '&.MuiButton': {
    '&-containedPrimary': {
      borderRadius: '53.2609px',

      '&.Mui-disabled': {
        opacity: '0.5',
        background: COLORS.lightGreen,
      },
    },

    '&-containedSecondary': {
      borderRadius: '53.2609px',
      background: COLORS.amour,
    },

    '&-outlinedInfo': {
      background: 'unset',
      border: 'none',

      '&:hover': {
        background: 'unset',
      },

      '&.Mui-disabled': {
        opacity: '0.5',
      },
    },

    '&-sizeLarge': {
      minWidth: '290px',
      padding: '22px 0',
      fontSize: '24.3478px',
    },

  },

  '&:hover': {
    background: COLORS.orange,
    border: `1px solid ${COLORS.darkBlue}`,
  },
}));
