import { useEffect } from 'react';

import Routes from '@routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { TimerContext } from 'context/timer-context';
import AxiosInterceptor from '@components/AxiosInterceptor';
import { Helmet } from 'react-helmet';

import { ALL_ROUTES } from '@appTypes/enums/pages';

import { useMount } from '@hooks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getMoveInfoThunk } from '@store/features/order/order.thunks';
import { getUserInfoThunk } from '@store/features/auth/auth.thunks';
import { useRouter } from '@hooks/useRouter';
import { useTimeStamp } from '@hooks/useTimeStamp';

const App = () => {
  const dispatch = useAppDispatch();

  const timerStampValue = useTimeStamp();

  const hash = useAppSelector(({ order }) => order.hash);
  const accessToken = useAppSelector(({ auth }) => auth.accessToken);
  const { location: { pathname }, navigate } = useRouter();

  const isHomePage = pathname === '/';
  const isRoom = pathname === '/room';
  const isInventory = pathname === '/inventory';

  const checkIsExistRoute = () => {
    const isExistRoute = Object.values(ALL_ROUTES).includes(pathname as ALL_ROUTES);

    if (!isExistRoute) {
      navigate('/404');
    }
  };

  useMount(() => {
    checkIsExistRoute();

    if (accessToken) {
      dispatch(getUserInfoThunk());// TODO: Must integrate refresh token
    }
  });

  useEffect(() => {
    if (hash && !isHomePage && !isRoom && !isInventory) {
      dispatch(getMoveInfoThunk({ hash }));
    }
  }, [dispatch, hash, isHomePage, isInventory, isRoom]);

  return (
    <div className="App">
      <Helmet>
        <title>Full Service Moving Company. Nationwide. Free Moving Boxes.</title>
        <meta
          name="description"
          content="Looking for reliable moving services? Our professional movers provide efficient
            and stress-free moving services for your home or business. Contact us today!"
        />
      </Helmet>
      <TimerContext.Provider value={timerStampValue}>
        <AxiosInterceptor>
          <Routes />
          <ToastContainer />
        </AxiosInterceptor>
      </TimerContext.Provider>
    </div>
  );
};

export default App;
