export const MOBILE_DRAWER_WIDTH = '100%';
export const ROOM_DRAWER_WIDTH_MOBILE = '100%';
export const ROOM_DRAWER_WIDTH_WEB = 373;
export const TOOLBAR_MIN_HEIGHT = 103;
export const SCROLL_HEIGHT_INVENTORY_REVIEW = 400;
export const SCROLL_HEIGHT_INVENTORY = 205;
export const CLEAR_STORAGE_HOURS = 72;

export enum ACTION_STATUSES {
  HOLD = 0,
  PENDING = 1,
  SUCCEED = 2,
  FAILED = 3,
}

export enum StorageKeysEnum {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  MOVE_HASH = 'hash',
  USER = 'user',
  FROM_DATE = 'fromDate',
  TO_DATE = 'toDate',
  TO_LOACATION = 'toLoacation',
  FROM_LOACATION = 'fromLoacation',
  EXACT_DATE = 'exactDate',
  HOUSE_ITEMS = 'houseItems',
  INVENTTORY_ITEMS = 'inventoryItems',
  ROOM_ITEMS = 'roomItems',
  ORDER = 'order',
  SETUP_TIME = 'setupTime',
}

export const MOVE_LIFE_TIME_STAMP = 259200000;// # 72 hours in milleseconds

export const STRIPE = {
  PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
};

const AWS_S3_BUCKET_NAME: string = process.env.REACT_APP_S3_BUCKET_NAME || '';

export const AMAZON_S3_CDN_URL = `https://${AWS_S3_BUCKET_NAME}.s3.amazonaws.com`;
export const AWS_S3_PREFIX: string = '/s3/putUrl';

export const ErrorMessages = {
  NAME_REQUIRED: 'Name is required',
  WIDTH_REQUIRED: 'Width is required',
  HEIGHT_REQUIRED: 'Height is required',
  LENGTH_REQUIRED: 'Length is required',
  PHONE_REQUIRED: 'Phone is required',
  EMAIL_REQUIRED: 'Email is required',
  PASSWORD_REQUIRED: 'Password is required',
  CONFIRM_PASSWORD: 'You should confirm your password.',
  PASSWORDS_MATCH: 'Passwords must match.',

  MIN_CHARACTER: 'Title must be at least 3 character',
  MAX_CHARACTER: 'Title be less than 255 characters',
  MAX_VALUE: 'Max value 1000',
};

export const BackErrorMessages = {
  TRANSACTION_ALREADY_CREATED: 'TRANSACTION_ALREADY_CREATED',
  UNCOMPLETED_MOVE: 'UNCOMPLETED_MOVE',
  INVALID_USER: 'INVALID_USER',
  INVALID_STATUS: 'INVALID_STATUS',
  ALREADY_VERIFIED: 'ALREADY_VERIFIED',
  ALREADY_REJECTED: 'ALREADY_REJECTED',
  INVALID_STEP: 'INVALID_STEP',
  INVALID_MOVE: 'INVALID_MOVE',
  MOVE_ALREADY_HAVE_TRANSACTION: 'MOVE_ALREADY_HAVE_TRANSACTION',
  MOVE_ALREADY_SIGNED: 'MOVE_ALREADY_SIGNED',
  PARTNER_INACTIVE: 'PARTNER_INACTIVE',
  USER_ALREADY_EXIST: 'USER_ALREADY_EXIST',
  USERNAME_ALREADY_EXIST: 'USERNAME_ALREADY_EXIST',
  EMAIL_ALREADY_EXIST: 'EMAIL_ALREADY_EXIST',
  PASSWORD_NOT_MATCHED: 'PASSWORD_NOT_MATCHED',
  INVALID_CURRENT_PASSWORD: 'INVALID_CURRENT_PASSWORD',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
  INVALID_TOKEN: 'INVALID_TOKEN',
  ACCESS_DENIED: 'ACCESS_DENIED',
  UNAUTHORIZED: 'UNAUTHORIZED',
  AWS_ERROR: 'AWS_ERROR',
  GOOGLE_API_ERROR: 'GOOGLE_API_ERROR',
  INVALID_MOVEMENT: 'INVALID_MOVEMENT',
  BAD_REQUEST: 'BAD_REQUEST',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  INVALID_STEP_FOR_SIGN: 'INVALID_STEP_FOR_SIGN',
  DOCUMENT_DECLINED: 'DOCUMENT_DECLINED',
  CHECK_EMAIL_FOR_SIGN: 'CHECK_EMAIL_FOR_SIGN',
  MANUALLY_SIGNED: 'MANUALLY_SIGNED',
  ALREADY_SIGNED: 'ALREADY_SIGNED',
  HAS_NOT_DEFAULT_CARD: 'HAS_NOT_DEFAULT_CARD',
  INVALID_CARD: 'INVALID_CARD',
  THERE_ARE_PENDING_BOOK: 'THERE_ARE_PENDING_BOOK',
};
