import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import Button from '@components/Button';

import { PAGE_ROUTES_PRIVATE, PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages';

import { useRouter } from '@hooks/useRouter';
// import { setIsOpenContactUsPopupAction } from '@store/features/auth/auth.actions';
// setIsTruckerAboutPopupOpen,
import { setIsTruckerFaqPopupOpen } from '@store/features/app/app.slice';
import { StyledLink } from './styled';

const TruckerNavbar = () => {
  const dispatch = useDispatch();
  const { navigate } = useRouter();

  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: '37px',
        alignItems: 'center',
      }}
    >
      <StyledLink
        label="About Us"
        onClick={() => navigate(PAGE_ROUTES_PUBLIC.ABOUT)}
      />

      <StyledLink
        label="Main Site"
        onClick={() => navigate(PAGE_ROUTES_PRIVATE.HOME)}
      />

      <StyledLink
        onClick={() => dispatch(setIsTruckerFaqPopupOpen(true))}
        label="F.A.Q"
      />
      <StyledLink
        onClick={() => {
          window.location.replace('https://partner.moomovesyou.com/login');
        }}
        label="Partners Login"
      />
      <Button
        sx={{
          width: 173,
          height: 42,
        }}
        onClick={() => navigate(PAGE_ROUTES_PRIVATE.TRUCKER_BECOME_PARTNER)}
      >
        Become A Partner
      </Button>
    </Box>
  );
};

export default TruckerNavbar;
