import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import _ from 'lodash';

import {
  MoveCreateDateModel,
  MoveCreateHouseModel,
  MoveCreateInitStepModel,
  MoveCreateInsuranceModel,
  MoveCreateInventoriesModel,
  MoveCreateLocationModel,
  MoveCreateModel,
  MoveCreatePeopleModel,
  MoveCreateRoomModel,
  MoveCreateSignatureModel,
  MoveCreateTransactionModel,
} from '@appTypes/model/create.move.model';
import { CustomRoomModel } from '@appTypes/model/room.model';
import { MoveRoom } from '@appTypes/model/allMoveInfo.model';

import StorageManager from '@utils/storage-manager';
import HouseService from '@services/HouseService';
import RoomService from '@services/RoomService';
import MooveService from '@services/MooveService';
import { StorageKeysEnum } from '@utils/constants';
import { setAllRoomsAction, setResetMoveAction } from './order.actions';
import ToastService from '@services/ToastService';

const prefix = 'order';

export const getHousesThunk = createAsyncThunk(
  `${prefix}/get/house`,
  async (a: any, { rejectWithValue }) => {
    try {
      return await HouseService.getAll();
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const getRoomsThunk = createAsyncThunk(
  `${prefix}/get/moves`,
  // TODO: make IParams type (searchKey)
  async (params: any, { rejectWithValue }) => {
    try {
      return await RoomService.getAll(params);
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

// TODO: Move middlewarete loogic not completed, first retrive data
export const createInitMooveThunk = createAsyncThunk(
  `${prefix}/create/move`,
  async (payload: MoveCreateInitStepModel, { rejectWithValue }) => {
    try {
      const result = await MooveService.createMoveApi<
        MoveCreateInitStepModel,
        AxiosResponse<Omit<MoveCreateInitStepModel, 'step'>>
      >(payload);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const createLocationMooveThunk = createAsyncThunk(
  `${prefix}/create/move/location`,
  async (payload: MoveCreateLocationModel, { rejectWithValue }) => {
    try {
      const result = await MooveService.createMoveApi<MoveCreateLocationModel>(
        payload,
      );

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const createDateMooveThunk = createAsyncThunk(
  `${prefix}/create/move/date`,
  async (payload: MoveCreateDateModel, { rejectWithValue }) => {
    try {
      const result = await MooveService.createMoveApi<
        MoveCreateDateModel,
        AxiosResponse<Omit<MoveCreateDateModel, 'fromDate' | 'toDate' | 'step'>>
      >(payload);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const createHouseMooveThunk = createAsyncThunk(
  `${prefix}/create/move/house`,
  async (payload: MoveCreateHouseModel, { rejectWithValue }) => {
    try {
      payload;

      const result = await MooveService.createMoveApi<MoveCreateHouseModel>(payload);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const createRoomMooveThunk = createAsyncThunk(
  `${prefix}/create/move/room`,
  async (payload: MoveCreateRoomModel, { rejectWithValue }) => {
    try {
      const result = await MooveService.createMoveApi<MoveCreateRoomModel>(payload);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const createInventoryMooveThunk = createAsyncThunk(
  `${prefix}/create/move/inventory`,
  async (payload: MoveCreateInventoriesModel, { rejectWithValue }) => {
    try {
      const result = await MooveService.createMoveApi<MoveCreateInventoriesModel>(payload);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const createPeopleMooveThunk = createAsyncThunk(
  `${prefix}/create/move/people`,
  async (payload: MoveCreatePeopleModel, { rejectWithValue }) => {
    try {
      const result = await MooveService.createMoveApi<MoveCreatePeopleModel>(payload);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const getCurrentStepMooveThunk = createAsyncThunk(
  `${prefix}/create/move/currentStep`,
  async ({ hash }: {hash: MoveCreateModel['hash']}, { rejectWithValue }) => {
    try {
      const result = await MooveService.getCurrentStep(hash);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const getMoveInfoThunk = createAsyncThunk(
  `${prefix}/create/move/info`,
  async ({ hash }: {hash: MoveCreateModel['hash']}, { rejectWithValue }) => {
    try {
      const result = await MooveService.getMoveInfo(hash);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const getCheckDocumentStatusThunk = createAsyncThunk(
  `${prefix}/get/document/status`,
  async ({ hash }: {hash: MoveCreateModel['hash']}, { rejectWithValue }) => {
    try {
      const result = await MooveService.getCheckDocumentStatus(hash);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const getCalcPriceMooveThunk = createAsyncThunk(
  `${prefix}/create/move/calcPrice`,
  async ({ hash }: {hash: MoveCreateModel['hash']}, { rejectWithValue }) => {
    try {
      const result = await MooveService.getPrice(hash);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const createInsuranceMoveThunk = createAsyncThunk(
  `${prefix}/create/insurance`,
  async (payload: MoveCreateInsuranceModel, { rejectWithValue }) => {
    try {
      const result = await MooveService.createMoveApi<MoveCreateInsuranceModel>(payload);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const createSignatureMoveThunk = createAsyncThunk(
  `${prefix}/create/signature`,
  async (payload: MoveCreateSignatureModel, { rejectWithValue }) => {
    try {
      const result = await MooveService.createMoveApi<MoveCreateSignatureModel>(payload);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const createTransactionMoveThunk = createAsyncThunk(
  `${prefix}/create/transaction`,
  async (payload: MoveCreateTransactionModel, { rejectWithValue }) => {
    try {
      const result = await MooveService.createMoveApi<MoveCreateTransactionModel>(payload);

      return result;
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);

export const resetMoveThunk = createAsyncThunk(
  `${prefix}/remove/moveHash`,
  async ({ moveId }: { moveId?: string }, { rejectWithValue, dispatch }) => {
    try {
      if (moveId) {
        await MooveService.cancel(moveId);

        ToastService.success('Move has been successfully canceled');
      }

      StorageManager.clearItems('session');
      StorageManager.removeItem(StorageKeysEnum.MOVE_HASH, 'local');
      StorageManager.removeItem(StorageKeysEnum.ORDER, 'session');

      dispatch(setResetMoveAction());
    } catch (err) {
      const result = rejectWithValue(err);

      ToastService.error('Reset move went wrong');

      return result;
    }
  },
);

export const createIntentThunk = createAsyncThunk(
  `${prefix}/createIntent`,
  async (moveId: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await MooveService.createIntent(moveId);

      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const changeAcceptStatus = createAsyncThunk(
  `${prefix}/changeAcceptStatus`,
  async ({ moveId, accept }: {moveId: string; accept: boolean}, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await MooveService.changeAcceptStatus(moveId, accept);

      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getCreatedOrNotCreatedAddRoomsThunk = createAsyncThunk(
  `${prefix}/createdOrNotCreatedAddRoomsThunk`,
  async (
    {
      createdRooms,
      selectedHouseId,
    }: {
            createdRooms: MoveRoom[] | undefined;
            selectedHouseId: string;
        },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const isCreatedRooms = createdRooms?.length;

      const house = await HouseService.getOne(selectedHouseId);
      const { houseRooms } = house ?? {};

      if (isCreatedRooms) {
        // INFO: if have dublicates titles
        const result = _(createdRooms)
          .groupBy('room.title')
          .values()
          .map((group) => ({ ...group[0], count: group.length }))
          .value() as unknown as MoveRoom[];

        // FIXME:  here must come 'isConfirm' in createdRooms (must add in backend)
        const mapedCreatedRooms: CustomRoomModel[] = result.map((r) => {
          const {
            roomId,
            count,
            room: { image, title },
          } = r;

          return {
            id: roomId,
            image,
            title,
            count,
          } as CustomRoomModel;
        });

        // INFO: for show created rooms and its created count, and rest rooms
        const { data: allRooms } = await RoomService.getAll({
          limit: 100,
          offset: 0,
        });

        const roomsWithCreatedCount: CustomRoomModel[] = allRooms.map((allRoom) => {
          const { id: roomId } = allRoom;
          const foundRoomsWIthCount = mapedCreatedRooms.find(
            (cr) => cr.id === roomId,
          );

          return foundRoomsWIthCount
            ? ({ ...allRoom, count: foundRoomsWIthCount.count } as CustomRoomModel)
            : allRoom;
        });

        dispatch(setAllRoomsAction(roomsWithCreatedCount));
      }

      // INFO: handle not created rooom
      if (selectedHouseId && !isCreatedRooms) {
        const { data: allRooms } = await RoomService.getAll({
          limit: 100,
          offset: 0,
        });

        const roomsWithInitialCount: CustomRoomModel[] = allRooms.map((r) => {
          const { id: roomId } = r;
          const foundRoomsWIthCount = houseRooms.find(
            (h: any) => h.room.id === roomId,
          );

          return foundRoomsWIthCount
            ? ({ ...r, count: foundRoomsWIthCount.count } as CustomRoomModel)
            : r;
        });

        dispatch(setAllRoomsAction(roomsWithInitialCount));
      }

      return {
        house,
      };
    } catch (err) {
      const result = rejectWithValue(err);

      return result;
    }
  },
);
