/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ILoginPayload,
  IUpdatePassword,
  IRegisterPayload, IResetPasswordPayload, ISetNewPasswordPayload, IUpdateProfile,
} from '@store/features/auth/auth.types';
import AuthService from '@services/AuthService';
import StorageManager from '@utils/storage-manager';
import { StorageKeysEnum } from '@utils/constants';
import { resetAuthAction, setUserAction } from './auth.actions';
import { resetMoveThunk } from '../order/order.thunks';

export const loginThunk = createAsyncThunk(
  'auth/login',
  async (payload: ILoginPayload, { rejectWithValue }) => {
    try {
      const { data: tokens } = await AuthService.login(payload);

      StorageManager.setItem(StorageKeysEnum.ACCESS_TOKEN, tokens.accessToken);
      StorageManager.setItem(StorageKeysEnum.REFRESH_TOKEN, tokens.refreshToken);

      const { data: user } = await AuthService.getProfile();

      return {
        ...tokens,
        user,
      };
    } catch (error: any) {
      return rejectWithValue(error.data.details[0].message as string);
    }
  },
);

export const registerThunk = createAsyncThunk(
  'auth/register',
  async (payload: IRegisterPayload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await AuthService.register(payload);
      const { accessToken, refreshToken } = data;

      StorageManager.setString(StorageKeysEnum.ACCESS_TOKEN, accessToken);
      StorageManager.setString(StorageKeysEnum.REFRESH_TOKEN, refreshToken);

      const { data: user } = await AuthService.getProfile();

      dispatch(setUserAction(user));
    } catch (error: any) {
      return rejectWithValue(error.data.details[0].message as string);
    }
  },
);

export const logoutThunk = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await AuthService.logout();
    } catch (error: any) {
      return rejectWithValue(error.message as string);
    } finally {
      StorageManager.clearItems('local');
      StorageManager.clearItems('session');

      dispatch(resetAuthAction());
    }
  },
);

export const getUserInfoThunk = createAsyncThunk(
  'auth/getUserInfo',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data: user } = await AuthService.getProfile();

      dispatch(setUserAction(user));
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updatePasswordThunk = createAsyncThunk(
  'auth/password/update',
  async (data: IUpdatePassword, { rejectWithValue }) => {
    try {
      return await AuthService.changePassword(data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateProfileThunk = createAsyncThunk(
  'auth/profile/update',
  async (data: IUpdateProfile, { rejectWithValue }) => {
    try {
      return await AuthService.updateProfile(data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const resetPasswordThunk = createAsyncThunk(
  'auth/password/reset',
  async (data: IResetPasswordPayload, { rejectWithValue }) => {
    try {
      return await AuthService.resetPassword(data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const setNewPasswordThunk = createAsyncThunk(
  'auth/password/setNewPassword',
  async (data: ISetNewPasswordPayload, { rejectWithValue }) => {
    try {
      return await AuthService.setNewPassword(data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
