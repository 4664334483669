import {
  Action,
  configureStore,
  Dispatch,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import appReducer from '@store/features/app/app.slice';
import orderReducer from '@store/features/order/order.slice';
import authReducer from '@store/features/auth/auth.slice';
import StorageManager from '@utils/storage-manager';
import { StorageKeysEnum } from '@utils/constants';
import { OrderState } from './features/order/types';
import { RootState } from './types';

const saveOrderState = (order: OrderState) => {
  const sessionOrder = StorageManager.getItem(StorageKeysEnum.ORDER);

  if (!isEqual(sessionOrder, order)) {
    StorageManager.setItem(StorageKeysEnum.ORDER, order);
  }
};

export const orderMiddleware =
  (api: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
    // Here you have access to `action.type` and `action.payload`

    const { order }: RootState = api.getState() ?? {};

    const isOrder = action.type.slice(0, 5) === 'order';

    if (isOrder) {
      saveOrderState(order);
    }

    // You should always do this at the end of your middleware
    return next(action);
  };

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(orderMiddleware),
  reducer: {
    app: appReducer,
    auth: authReducer,
    order: orderReducer,
  },
});
