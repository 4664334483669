import { useMemo } from 'react';

import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
  useMatch,
} from 'react-router-dom';
import queryString from 'query-string';

import { AllRoutesType } from '@appTypes/enums/pages';

interface IUseRouter {
  match?: string;
}

export function useRouter(props?: IUseRouter) {
  const { match = '' } = props ?? {};
  const params = useParams();
  const location = useLocation();
  const searchParams = useSearchParams();
  const routeMatch = useMatch(match);

  const navigate = useNavigate();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  const customNavigate = (pathname: AllRoutesType | number) => {
    navigate(pathname as any);
  };

  return useMemo(() => {
    return {
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      searchParams,
      params,
      match: routeMatch,
      location: {
        ...location,
        pathname: location.pathname as AllRoutesType,
      },
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      navigate: customNavigate,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, params, location, navigate]);
}
