import { createAsyncThunk } from '@reduxjs/toolkit';

import { IContactUsFormValues } from '@pages/ContactUsPopup';
import { http } from '../../../api/RequestApi';

export const contactUsThunk = createAsyncThunk(
  'app/contactUs',
  async (payload: IContactUsFormValues, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await http.post(
        'contactUs',
        payload,
      );

      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.data.details[0].message as string);
    }
  },
);
