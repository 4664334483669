import { http } from 'api/RequestApi';

import { IPagination } from '@appTypes/global';
import { RoomModelItems } from '@appTypes/model/room.model';

class RoomService {
  static prefix = '/rooms';

  static async getAll(params?: IPagination) {
    const response = await http.get<RoomModelItems>(
      `${this.prefix}`,
      { params },
    );

    return response.data;
  }
}

export default RoomService;
