import { EmailLink, StepperBox, StyledAboutContent, StyledText, StyledTitle } from '@pages/AboutUs/styled';
import { StepperItem, StepperLine, StepperNumber, StepperText } from '@pages/Home/newDesign';
import addressIcon from '@assets/svg/stepper/address.svg';
import dateIcon from '@assets/svg/stepper/date.svg';
import boxesIcon from '@assets/svg/stepper/boxes.svg';
import inventoryIcon from '@assets/svg/stepper/inventory.svg';
import quoteIcon from '@assets/svg/stepper/quote.svg';

const HowItWorks = () => {
  return (
    <StyledAboutContent>

      <div>
        <StyledTitle>How Moo Works</StyledTitle>

        <StepperBox>

          <StepperItem>
            <StepperNumber><img src={addressIcon} alt="addressIcon" /></StepperNumber>
            <StepperText>Address</StepperText>
          </StepperItem>

          <StepperLine />

          <StepperItem>
            <StepperNumber><img src={dateIcon} alt="dateIcon" /></StepperNumber>
            <StepperText>Dates</StepperText>
          </StepperItem>

          <StepperLine />

          <StepperItem>
            <StepperNumber><img src={boxesIcon} alt="boxesIcon" /></StepperNumber>
            <StepperText>Inventory</StepperText>
          </StepperItem>

          <StepperLine />

          <StepperItem>
            <StepperNumber><img src={inventoryIcon} alt="inventoryIcon" /></StepperNumber>
            <StepperText>Boxes</StepperText>
          </StepperItem>

          <StepperLine />

          <StepperItem>
            <StepperNumber><img src={quoteIcon} alt="quoteIcon" /></StepperNumber>
            <StepperText fontWeight="bold">Your Quote!</StepperText>
          </StepperItem>
        </StepperBox>

        <StyledText>
          Moo has a nationwide network of moving companies that we work with. All of these companies
          have agreed to the price that we are offering you and the one that has availability for the pickup
          dates you requested will claim your job.

          <br />
          <br />
          After you confirm your move, be on the lookout for emails from us. You will receive the tracking
          number for your boxes and you will be connected directly to the moving company that will be
          handling your move.

          <br />
          <br />
          The customer service representative from your moving company will be calling you in the next
          few days. If you have any questions or need to add any furniture or boxes, you will have the
          opportunity to do this! Don’t worry, your price is locked in. If you have to add stuff, you will pay
          the same price per cubic foot for these additional items as the rest of your move!

          <br />
          <br />
          All of our moves are full service! On the day of your move, the movers will disassemble your
          bed and wrap all of your furniture in protective blankets. When they arrive at your new house, all
          of your furniture will be put back together and placed where you want it!

          <br />
          <br />
          Start packing your boxes. All clothing and personal items should be packed in the boxes we
          send you. If you need help packing, most of the moving companies we work with offer packing
          services. You can add this service when your customer service rep calls you.

          <br />
          <br />
          Sit back and relax, everything is under control!
          <br />
          If you need anything, email us at
          <EmailLink href="mailto:support@moomovesyou.com">support@moomovesyou.com</EmailLink>
          to speak with one of our Customer Success Managers!
        </StyledText>
      </div>

    </StyledAboutContent>
  );
};

export default HowItWorks;
