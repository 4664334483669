import { styled } from '@mui/material/styles';

export const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  opacity: 0.5,
  ...(ownerState.active && {
    opacity: 1,

  }),
  ...(ownerState.completed && {
    opacity: 1,
  }),
}));
