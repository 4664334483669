import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import Center from '@components/AppBar/components/Center';
import { StyledLogo, StyledToolbar } from '@components/AppBar/styled';
import { StyledLink } from '@components/AppBar/components/TruckerNavbar/styled';
import { SxProps } from '@mui/material';
import { AFFILIATE_URL } from '@configs';
import TruckerMenuLinks from '@components/MobileDrawer/components/MobileDrawerMenu/TruckerMenuLinks';

import { PAGE_ROUTES_PRIVATE, PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages';

import { Links, SignUpButton } from './styled';
// import { useAppDispatch } from '@store/hooks';
// import { setIsTruckerAboutPopupOpen } from '@store/features/app/app.slice';

interface AffiliateNavbarProps {
  sxProps?: SxProps;
}

const AffiliateNavbar: FC<AffiliateNavbarProps> = ({
  sxProps,
}) => {
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  // const path = useLocation().pathname;

  return (
    <>
      <StyledToolbar
        sx={sxProps}
      >
        <StyledLogo>
          <Center />
        </StyledLogo>

        <Links>
          <StyledLink
            label="About Us"
            onClick={() => navigate(PAGE_ROUTES_PUBLIC.ABOUT)}
          />
          <StyledLink
            label="Main Site"
            onClick={() => navigate(PAGE_ROUTES_PRIVATE.HOME)}
          />
          <StyledLink
            label="FAQ"
            onClick={() => navigate(PAGE_ROUTES_PUBLIC.FAQ)}
          />
          <StyledLink
            label="Referral login"
            onClick={() => window.location.href = AFFILIATE_URL!}
          />
          <SignUpButton variant="contained" onClick={() => navigate(PAGE_ROUTES_PUBLIC.AFFILIATE_BECOME_PARTNER)}>
            Sign Up
          </SignUpButton>

          {/* {path === PAGE_ROUTES_PUBLIC.AFFILIATE_BECOME_PARTNER
            ? (
              <StyledLink
                label="How Referral Works"
                onClick={() => navigate(PAGE_ROUTES_PUBLIC.AFFILIATE)}
              />
            ) : (
              <StyledLink
                label="FAQ"
                onClick={() => navigate(PAGE_ROUTES_PUBLIC.FAQ)}
              />
            )} */}
        </Links>
      </StyledToolbar>
    </>
  );
};

export const AffiliateMobileBar = ({ onClose }: { onClose: () => void }) => {
  return (
    <div>
      <TruckerMenuLinks onClose={onClose} />
    </div>
  );
};

export default AffiliateNavbar;
