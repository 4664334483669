import { Components, ThemeOptions, Theme } from '@mui/material/styles';

import { FontFamilyNames } from '@appTypes/fonts';

import MoretFont from '@assets/fonts/Moret';
import PlainFont from '@assets/fonts/Plain';
import { COLORS } from '@utils/colors';

export const typography: ThemeOptions['typography'] = (/* theme */) => ({
  fontWeightBold: 700,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  fontWeightLight: 300,
  allVariants: {
    color: COLORS.darkBlue,
  },
  fontFamily: [...PlainFont.fontFamily, ...MoretFont.fontFamily, 'sans'].join(
    ', ',
  ),
});

export const MuiTypography: Components['MuiTypography'] = {
  styleOverrides: {
    root: {
      letterSpacing: '-0.02em',
    },
  },
};

export const setTypographyMedias = (theme: Theme) => {
  theme.typography = {
    ...theme.typography,
    h1: {
      fontSize: '64px',
      fontFamily: FontFamilyNames.MoretBook,
      lineHeight: '74px',

      [theme.breakpoints.down('lg')]: {
        fontSize: '40px',
        lineHeight: '44px',
      },

      [theme.breakpoints.down('md')]: {
        fontSize: '27px',
        lineHeight: '33px',
      },
    },
    h2: {},
    h3: {
      fontSize: '40px',
      lineHeight: '46.5px',
      fontFamily: FontFamilyNames.MoretBook,

      [theme.breakpoints.down('md')]: {
        fontSize: '30px',
        lineHeight: '34.8px',
      },
    },
    h4: {
      fontSize: '32px',

      [theme.breakpoints.down('md')]: {
        fontSize: '22px',
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    h5: {
      fontSize: '24px',

      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
      },
    },
    h6: {
      fontSize: '20px',
      lineHeight: '135%',

      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    body1: {
      fontSize: '16px',
      fontFamily: FontFamilyNames.PlainLight,
      lineHeight: ' 125%',

      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
  };
};
