import { styled, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@components/Typography';
import Button from '@components/Button';

import { COLORS } from '@utils/colors';

export const StyledBackground = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  minHeight: '100vh',
  paddingBottom: 0,
  paddingTop: 103,
  backgroundColor: COLORS.beige,

  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
  },
}));

export const MobileHeader = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: COLORS.beige,
    position: 'relative',
    overflowX: 'hidden',
    marginBottom: 10,
  },
}));

export const StyledMobileLogo = styled('img')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('sm')]: {
    display: 'block',
    position: 'absolute',
    top: 20,
    left: 20,
    maxWidth: 127,
  },
}));

export const MobileHeaderBackgroundImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const MobileHeaderButtons = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    top: '18%',
  },
}));

export const Header = styled(Stack)(
  ({ theme }) => ({
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    top: 145,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 999,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
);

export const StyledSwiperCarouselBox = styled(Box)(({ theme }) => ({
  backgroundColor: COLORS.beige,
  position: 'relative',
  paddingTop: 50,
  img: {
    maxHeight: 'calc(100vh - 280px)',
  },

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledContentBox = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'column',
}));

export const StyledTopContentBox = styled(StyledContentBox)(({ theme }) => ({
  width: 903,
  height: 272,
  background: 'radial-gradient(50% 50% at 50% 50%, #FFFFFF 18.18%, rgba(255, 255, 255, 0) 100%);',

  [theme.breakpoints.down('sm')]: {
    height: 'unset',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    lineHeight: '63px',
    letterSpacing: 0,
    marginTop: '0',

    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      textAlign: 'center',
    },
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    borderBottom: `2px solid ${COLORS.lightGreen}`,
    cursor: 'pointer',
    color: theme.palette.grey['50'],

    [theme.breakpoints.up('el')]: {
      marginTop: '41px',
    },
    [theme.breakpoints.down('el')]: {
      marginTop: '33px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      borderBottomColor: COLORS.lightGreen,
      marginTop: 0,
    },
  },
}));

export const HowItWorksButton = styled(SubTitle)(() => ({
  '&.MuiTypography-root': {
    marginTop: 15,
  },
}));

export const StyledButtonGetStarted = styled(Button)(
  ({ theme }: { theme: Theme }) => ({
    '&.MuiButton-root': {
      height: '68px',
      marginTop: '28px',
      fontSize: '24px',
      minWidth: '250px',
      ':hover': {
        background: COLORS.lightGreen,
      },
      [theme.breakpoints.down('el')]: {
        marginTop: '40px',
      },

      [theme.breakpoints.down('md')]: {
        minWidth: '200px',
        padding: '10px 0',
        fontSize: '18px',
      },
      '@media (max-height: 950px)': {
        marginTop: '40px',
      },

      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset',
        padding: '10px 25px',
        height: 44,
        fontSize: 16,
        marginTop: 27,
        marginBottom: 27,
      },
    },
  }),
);

export const StyledBlocks = styled(Stack)(
  ({ theme }) => ({
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    columnGap: 53,
    padding: '30px 150px',

    [theme.breakpoints.down('sm')]: {
      marginBottom: 137,
      padding: 0,
      flexDirection: 'column',
      gap: 67,
    },
  }),

);

export const StyledBlock = styled(Stack)(({ theme }) => ({
  maxWidth: '310px',
  width: '100%',
  padding: '0',
  paddingBottom: 53,
  textAlign: 'center',
  alignItems: 'center',

  img: {
    maxWidth: '150px',
  },

  '&.first': {
    button: {
      width: '100%',
      maxWidth: 250,
      marginTop: '12px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'unset',
    padding: '0 20px',

    img: {
      maxWidth: '100px',
    },
  },
}),
);

export const StyledBlockTitle = styled(Typography)(
  ({ theme }) => ({
    fontWeight: 300,
    fontSize: 24,
    margin: '10px 0 20px 0',
    color: COLORS.darkBlue,

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      margin: '20px 0',
    },
  }),
);

export const StyledBlockImage = styled(Box)(
  ({ theme }) => ({
    paddingTop: 60,
    minHeight: 250,

    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      paddingTop: 0,
    },
  }),
);

export const StyledBlockDescription = styled(Typography)(
  ({ theme }) => ({
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '125%',
    color: COLORS.grey['50'],

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '130%',
    },
  }),
);

export const FooterBackground = styled(Box)(
  ({ theme }) => ({
    paddingBottom: 40,
    background: `linear-gradient(to top, ${COLORS.white} 19.86%, ${COLORS.beige} 94.05%)`,

    [theme.breakpoints.down('sm')]: {
      paddingBottom: 50,
    },
  }),
);
export const Footer = styled(Stack)(
  () => ({
    alignItems: 'center',
  }),
);

export const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey['50'],
  fontSize: '14px',
  margin: '50px 0',
  maxWidth: 723,
  textAlign: 'center',

  [theme.breakpoints.down('sm')]: {
    maxWidth: 'unset',
    padding: '0 20px',

  },
}));

export const FooterLinks = styled(Stack)(({ theme }) => ({
  gap: '51px',
  flexDirection: 'row',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 14,
    alignItems: 'center',
  },
}));

export const FooterLink = styled(Typography)(() => ({
  fontSize: '14px',
  color: COLORS.grey['50'],
  cursor: 'pointer',
}));

export const FooterNumbersStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: '300px',
  marginBottom: '30px',

  [theme.breakpoints.down('sm')]: {
    gap: '50px',
  },
}));

export const FooterNumber = styled(Typography)(() => ({
  fontSize: '14px',
  color: COLORS.grey['50'],
  fontWeight: 'bold',
  letterSpacing: 0.5,
}));

export const FooterInfo = styled(Typography)(() => ({
  fontSize: '14px',
  color: COLORS.grey['50'],
  textAlign: 'center',
  marginTop: '20px',
  fontWeight: 'bold',
  letterSpacing: 0.5,
  p: {
    marginBottom: '5px',
  },
}));
