import { styled } from '@mui/material/styles';
import Modal from '@components/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@components/Button';
import MuiPhoneNumber from 'react-phone-input-2';

import { FontFamilyNames } from '@appTypes/fonts';

import { COLORS } from '@utils/colors';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '100%',
    maxWidth: 670,
    borderRadius: 20,
  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,

    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%',
    },
  },
}));

export const StyledDialogContent = styled(Box)(() => ({
  padding: '55px 0 60px 0',
  borderRadius: 20,
}));

export const StyledModalTitle = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 40,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: 300,
  marginBottom: 10,

  '& .MuiOutlinedInput-root': {
    borderBottom: `1px solid ${COLORS.darkBlue}`,
    borderRadius: 0,
  },
  '& .MuiInputBase-input': {
    color: COLORS.darkBlue,
    fontSize: 16,
    padding: '17px 15px 15px 0',
    letterSpacing: '-0.02em',
  },

  '& ::placeholder': {
    color: COLORS.darkBlue,
    opacity: 0.5,
  },
  'input:-webkit-autofill': {
    boxShadow: '0 0 0 1000px white inset !important',
  },

  [theme.breakpoints.down('sm')]: {
    width: 'unset',
    '& .MuiOutlinedInput-root': {
      width: '256px',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
}));

export const StyledTextArea = styled('textarea')(({ theme }) => ({
  width: 300,
  border: `1px solid ${COLORS.darkBlue}`,
  borderRadius: 14,
  padding: '12px 15px 108px 15px',
  fontFamily: 'Plain-Light',
  fontSize: 16,
  marginTop: 21,
  marginBottom: 10,

  '&::placeholder': {
    opacity: 0.5,
    color: COLORS.darkBlue,
  },

  [theme.breakpoints.down('sm')]: {
    paddingBottom: 176,
    width: '256px',
  },
}));

export const StyledLoginButton = styled(Button)(({ theme }) => ({
  marginTop: 27,
  width: 158,
  fontSize: '24px',
  padding: '4px 30px',
  fontFamily: FontFamilyNames.MoretRegular,

  [theme.breakpoints.down('sm')]: {
    marginTop: 44,
    padding: '9px 45px',
    width: 'unset',
    height: 36,
    fontSize: 16,
  },
}));

export const StyledLeftLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledRightLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18,
  },
}));

export const StyledPhoneNumber = styled(MuiPhoneNumber)(({ theme }) => ({
  width: '300px',
  borderBottom: `1px solid ${COLORS.darkBlue}`,
  borderRadius: 0,
  input: {
    width: '100%',
    border: 'none',
    background: 'transparent',
    color: COLORS.darkBlue,
    padding: '17px 15px  15px 0',
    fontSize: '16px',
  },
  '.form-control::placeholder': {
    color: COLORS.darkBlue,
    opacity: 0.5,
  },
  '.form-control:focus-visible': {
    outlineWidth: 0,
  },
  '& .special-label': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    width: '256px',
    input: {
      fontSize: '14px',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
}));
