import { createContext } from 'react';

import noop from 'lodash/noop';

import StorageManager from '@utils/storage-manager';
import { StorageKeysEnum } from '@utils/constants';

export interface ITimerContext {
  setupTime: string;
  createTimeStamp: () => void;
  clearTimeStamp: () => void;
}

export const initialTimerValue: ITimerContext = {
  setupTime: StorageManager.getItem(StorageKeysEnum.SETUP_TIME) || '',
  createTimeStamp: noop,
  clearTimeStamp: noop,
};

export const TimerContext = createContext<ITimerContext>(initialTimerValue);
