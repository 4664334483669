import { css } from '@mui/material/styles';

import { FontFamilyNames, ThemeFont } from '@appTypes/fonts';

import PlainBoldTtf from './Plain-Bold.ttf';
import PlainBoldWoff2 from './Plain-Bold.woff2';
import PlainBoldWoff from './Plain-Bold.woff';
import PlainBoldItalicTtf from './Plain-BoldItalic.ttf';
import PlainBoldItalicWoff2 from './Plain-BoldItalic.woff2';
import PlainLight from './Plain-Light.ttf';
import PlainLightItalic from './Plain-LightItalic.ttf';

const PlainFont: ThemeFont = {
  fontFamily: [
    FontFamilyNames.PlainLight,
    FontFamilyNames.PlainBold,
    FontFamilyNames.PlainBoldItalic,
  ],
  fontFaces: css`
    @font-face {
      font-family: ${FontFamilyNames.PlainBold};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.PlainBold}),
        url(${PlainBoldWoff2}) format("woff2"),
        url(${PlainBoldWoff}) format("woff"),
        url(${PlainBoldTtf}) format("truetype");
    }

    @font-face {
      font-family: ${FontFamilyNames.PlainBoldItalic};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.PlainBoldItalic}),
        url(${PlainBoldItalicWoff2}) format("woff2"),
        url(${PlainBoldWoff}) format("woff"),
        url(${PlainBoldItalicTtf}) format("truetype");
    }

    @font-face {
      font-family: ${FontFamilyNames.PlainLight};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.PlainLight}),
        url(${PlainLight}) format("truetype");
    }

    @font-face {
      font-family: ${FontFamilyNames.PlainLightItalic};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.PlainLightItalic}),
        url(${PlainLightItalic}) format("truetype");
    }
  `,
};

export default PlainFont;
