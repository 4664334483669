import { Fragment, useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import { FieldPath, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ErrorMessage from '@components/ErrorMessage';
import { BurgerMenuButton } from '@components/AppBar/styled';
import { StyledErrorMessage, StyledSuccessMessage } from '@components/ErrorMessage/styled';

import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import contactUs1 from '@assets/svg/contactUs1.svg';
import contactUs2 from '@assets/svg/contactUs2.svg';
import {
  StyledDialogContent,
  StyledIconButton,
  StyledLeftLogo,
  StyledLoginButton,
  StyledModal,
  StyledModalTitle, StyledPhoneNumber,
  StyledRightLogo,
  StyledTextArea,
  StyledTextField,
} from './styled';
import { useAppDispatch } from '@store/hooks';
import { ContactUsFormSchema } from './schema';
import { COLORS } from '@utils/colors';
import { contactUsThunk } from '@store/features/app/app.thunks';

interface ContactUsPopupProps {
    isOpen: boolean;
    onClose: () => void;
}

export interface IContactUsFormValues {
    name: string;
    email: string;
    phone: string;
    message: string;
}

interface Field {
  name: FieldPath<IContactUsFormValues>;
  placeholder: string;
}

const fields: Field[] = [
  {
    name: 'name',
    placeholder: 'Your name',
  },
  {
    name: 'email',
    placeholder: 'Your email',
  },
];

const ContactUsPopup = ({ isOpen, onClose }: ContactUsPopupProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const clearMessages = () => {
    setMessage('');
    setError('');
  };

  const handleLogin: SubmitHandler<IContactUsFormValues> = async (data) => {
    clearMessages();
    setIsLoading(true);

    try {
      await dispatch(contactUsThunk(data)).unwrap();

      setMessage('Your message has been sent');

      reset();
    } catch (e) {
      setError('Something went wrong, try again');
    } finally {
      setIsLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm<IContactUsFormValues>({
    resolver: yupResolver(ContactUsFormSchema),
  });

  const { phone } = useWatch({
    control,
  });

  const handleClose = () => {
    clearMessages();
    reset();
    onClose();
  };

  return (
    <StyledModal open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ padding: 0 }}>

        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>

      <StyledDialogContent>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label="Contact Us"
          textAlign="center"
        />
        <form onSubmit={handleSubmit(handleLogin, clearMessages)}>
          <Stack alignItems="center">
            {fields.map((field) => (
              <Fragment key={field.name}>
                <StyledTextField
                  placeholder={field.placeholder}
                  variant="outlined"
                  type="text"
                  {...register(field.name)}
                />
                {errors[field.name] && (
                <ErrorMessage name={field.name} errors={errors} />
                )}
              </Fragment>
            ))}

            <StyledPhoneNumber
              placeholder="Your phone number"
              country="us"
              onlyCountries={['us']}
              disableCountryCode
              value={phone}
              onChange={(value) => setValue('phone', value, {
                shouldValidate: true,
              })}
            />
            {errors?.phone?.message && (
              <ErrorMessage name="phone" errors={errors} />
            )}

            <StyledTextArea
              placeholder="Your message"
              {...register('message')}
            />
            {errors.message && (
            <ErrorMessage name="message" errors={errors} />
            )}

            {!!error && (
            <StyledErrorMessage>
              {error}
            </StyledErrorMessage>
            )}

            {!!message && (
            <StyledSuccessMessage>
              {message}
            </StyledSuccessMessage>
            )}

            <StyledLoginButton
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              Send
            </StyledLoginButton>
          </Stack>
        </form>

        <StyledLeftLogo src={contactUs1} />
        <StyledRightLogo src={contactUs2} />
      </StyledDialogContent>
    </StyledModal>
  );
};

export default ContactUsPopup;
