import { FC, memo } from 'react';

import {
  BecomePartnerButton,
  StyledGetStartedButton,
  StyledLink,
  StyledStack,
} from '@components/MobileDrawer/components/MobileDrawerMenu/styled';
import Stack from '@mui/material/Stack';
import { useLocation } from 'react-router-dom';
import { AffiliateMobileBar } from '@components/AppBar/components/AffiliateNavbar';

import { PAGE_ROUTES_PRIVATE, PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages';

import TruckerMenuLinks from './TruckerMenuLinks';
import { ReactComponent as UserIcon } from '@assets/svg/user-icon.svg';
import { logoutThunk } from '@store/features/auth/auth.thunks';
import { useUnMount } from '@hooks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useRouter } from '@hooks/useRouter';
import {
  setIsOpenLoginPopupAction,
  setIsOpenRegisterPopupAction,
  setIsOpenContactUsPopupAction,
} from '@store/features/auth/auth.actions';
import { useInitContinueAndResetMoveActions } from '@hooks/useGetStartedAndContinue';

interface MobileDrawerMenuProps {
  onClose: () => void;
}

const MobileDrawerMenu: FC<MobileDrawerMenuProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { navigate } = useRouter();
  const { handleResetMove, handleGetStarted } =
    useInitContinueAndResetMoveActions();

  const hash = useAppSelector(({ order }) => order.hash);
  const { user } = useAppSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logoutThunk())
      .unwrap()
      .then(() => {
        onClose();
        // navigate(PAGE_ROUTES_PRIVATE.HOME);
      });

    // TODO: Maybe can need in future
    // if (pathname === '/my-profile' || pathname === '/quote') {
    //   navigate(PAGE_ROUTES_PRIVATE.HOME);
    // }
  };

  const handleGoToMyProfile = () => {
    navigate(PAGE_ROUTES_PRIVATE.MY_PROFILE);
    onClose();
  };

  const handeBecomePartner = () => {
    navigate(PAGE_ROUTES_PRIVATE.TRUCKER_LANDING);
    onClose();
  };

  useUnMount(() => {
    dispatch(setIsOpenRegisterPopupAction(false));
  });

  const getMenuLinks = () => {
    if (pathname.includes(PAGE_ROUTES_PRIVATE.TRUCKER_LANDING) ||
      pathname.includes(PAGE_ROUTES_PRIVATE.TRUCKER_BECOME_PARTNER)) {
      return <TruckerMenuLinks onClose={onClose} />;
    }

    if (pathname.includes(PAGE_ROUTES_PUBLIC.AFFILIATE) ||
      pathname.includes(PAGE_ROUTES_PUBLIC.AFFILIATE_BECOME_PARTNER)) {
      return <AffiliateMobileBar onClose={onClose} />;
    }

    return (
      <>
        {user ? (
          <StyledLink onClick={handleGoToMyProfile}>
            <UserIcon />
            {user.email}
          </StyledLink>
        ) : (
          <Stack spacing="20px" direction="row">
            <StyledGetStartedButton
              label={hash ? 'Continue' : 'Get started'}
              onClick={() => handleGetStarted(onClose)}
            />
            {hash && (
              <StyledGetStartedButton
                color="secondary"
                variant="contained"
                label="Reset Move"
                onClick={handleResetMove}
              />
            )}
          </Stack>
        )}

        {!user && (
          <BecomePartnerButton
            label="Become a partner"
            onClick={handeBecomePartner}
          />
        )}

        {user ? (
          <>
            <StyledLink>My Account</StyledLink>
            <StyledLink onClick={handleLogout}>Logout</StyledLink>
          </>
        ) : (
          <>
            <StyledLink
              onClick={() => dispatch(setIsOpenLoginPopupAction(true))}
            >
              Login
            </StyledLink>
            <StyledLink
              onClick={() => {
                dispatch(setIsOpenRegisterPopupAction(true));
              }}
            >
              Create account
            </StyledLink>
            <StyledLink
              onClick={() => {
                navigate(PAGE_ROUTES_PUBLIC.AFFILIATE);
                onClose();
              }}
            >
              Referral Partner
            </StyledLink>
          </>
        )}
        <StyledLink
          onClick={() => dispatch(setIsOpenContactUsPopupAction(true))}
        >
          Contact us
        </StyledLink>
        <StyledLink
          onClick={() => {
            navigate(PAGE_ROUTES_PUBLIC.FAQ);
            onClose();
          }}
        >
          FAQs
        </StyledLink>
        <StyledLink
          onClick={() => {
            navigate(PAGE_ROUTES_PUBLIC.ABOUT);
            onClose();
          }}
        >
          About us
        </StyledLink>
      </>
    );
  };

  return (
    <>
      <StyledStack>
        {getMenuLinks()}
      </StyledStack>

    </>
  );
};

export default memo(MobileDrawerMenu);
