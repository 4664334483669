import { useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ErrorMessage from '@components/ErrorMessage';
import { BurgerMenuButton } from '@components/AppBar/styled';

import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import mooLogo from '@assets/svg/moo-login.svg';
import {
  StyledModal,
  StyledIconButton,
  StyledDialogContent,
  StyledModalTitle,
  StyledTextField,
  StyledLoginButton,
  StyledLogo,
} from '../LoginMoal/styled';
import { useAppDispatch } from '@store/hooks';
import { ResetPasswordFormSchema } from './schema';
import { COLORS } from '@utils/colors';
import { resetPasswordThunk } from '@store/features/auth/auth.thunks';
import ToastService from '@services/ToastService';

interface ResetPasswordPopupProps {
  isOpen: boolean;
  handleClose: () => void;
}

interface IResetPasswordFormValues {
  email: string;
}

const ResetPasswordPopup = ({ isOpen, handleClose }: ResetPasswordPopupProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleReset: SubmitHandler<IResetPasswordFormValues> = (data) => {
    setIsLoading(true);
    dispatch(
      resetPasswordThunk(data),
    )
      .unwrap()
      .then(() => {
        handleClose();
        reset();
        ToastService.success('The link has been sent to your email');
      })
      .catch(() => {
        ToastService.error('Something went wrong');
      })
      .finally(() => setIsLoading(false));
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IResetPasswordFormValues>({
    resolver: yupResolver(ResetPasswordFormSchema),
  });

  return (
    <StyledModal keepMounted open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>

      <StyledDialogContent>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label="Reset password"
          textAlign="center"
        />
        <Stack
          component="form"
          autoComplete="off"
          alignItems="center"
          onSubmit={handleSubmit(handleReset)}
        >
          <StyledTextField
            placeholder="Your email address"
            variant="outlined"
            type="email"
            {...register('email', { value: '' })}
          />
          {errors.email && <ErrorMessage name="email" errors={errors} />}

          <StyledLoginButton
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            Reset
          </StyledLoginButton>
        </Stack>

        <StyledLogo src={mooLogo} />
      </StyledDialogContent>
    </StyledModal>
  );
};

export default ResetPasswordPopup;
