import { styled } from '@mui/material/styles';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import Button from '@components/Button';
import Stack from '@mui/material/Stack';

import { FontFamilyNames } from '@appTypes/fonts';

import { COLORS } from '@utils/colors';

export const HomePage = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  minHeight: '100vh',
  paddingBottom: 0,
  paddingTop: 131,
  backgroundColor: COLORS.beige,

  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
  },
}));

export const MobileLogo = styled('img')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('sm')]: {
    display: 'block',
    margin: '20px auto 49px auto',
    maxWidth: 127,
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    color: COLORS.darkBlue,
    fontSize: 20,
    marginTop: 10,
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      padding: '0 20px',
    },
  },
}));

export const LocationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '23px',
  marginTop: 41,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 8,
    padding: '0 20px',
  },
}));

export const PickDatesButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    padding: '0 45px',
    height: 45,

    fontSize: '16px',

    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
      padding: '10px 25px',
      height: 44,
      fontSize: 16,
      marginTop: 16,
      marginBottom: 27,
    },
  },
}));

export const StepperBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  marginTop: 33,
  marginBottom: 28,

  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
    marginTop: 41,
  },
}));

export const StepperItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StepperNumber = styled(Typography)(({ theme }) => ({
  fontSize: 34,
  color: '#82B5A5',

  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

export const StepperText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: COLORS.darkBlue,

  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

export const StepperLine = styled(Box)(({ theme }) => ({
  height: 1,
  width: '28px',
  margin: '7px 11px',
  background: '#82B5A5',

  [theme.breakpoints.down('sm')]: {
    width: '14px',
    margin: '5px 7px',
  },
}));

export const BannerBox = styled(Box)(({ theme }) => ({
  img: {
    width: '100%',
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: 80,
  },
}));

export const EstimateBox = styled(Box)(({ theme }) => ({
  marginBottom: 254,

  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
    marginBottom: 113,
  },
}));

export const EstimateTitle = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: 40,
  fontWeight: 400,
  fontFamily: FontFamilyNames.MoretRegular,
  marginBottom: 26,
}));

export const EstimateContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 70,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 37,
  },
}));

export const EstimateImageBox = styled(Box)(() => ({
  img: {
    width: '100%',
  },
}));

export const EstimateTextBox = styled(Box)(() => ({
  maxWidth: 404,
}));

export const EstimateText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: COLORS.darkBlue,
  marginBottom: 15,

  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

export const EstimateDescription = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: COLORS.grey['50'],

  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const HowItWorksButton = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    borderBottom: `2px solid ${COLORS.lightGreen}`,
    cursor: 'pointer',
    color: theme.palette.grey['50'],
    display: 'inline-block',
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
}));

export const Footer = styled(Box)(({ theme }) => ({
  padding: '0 77px',

  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
  },
}));

export const FooterContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '14px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },

}));

export const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey['50'],
  fontSize: '14px',
  margin: '50px auto 93px auto',
  maxWidth: 723,
  textAlign: 'center',

  [theme.breakpoints.down('sm')]: {
    maxWidth: 'unset',
    width: '100%',
    padding: '0',
    margin: '14px auto 60px auto',
  },
}));

export const FooterInfoBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 25,
    order: 2,
  },
}));

export const FooterLogo = styled('img')(() => ({
  marginBottom: 19,
}));

export const FooterInfoText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: 28,
  color: COLORS.grey['50'],

  [theme.breakpoints.down('sm')]: {

  },
}));

export const FooterLinksBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  // justifyContent: 'space-between',
  // flexDirection: 'row',
  gap: 47,
  // border: '1px solid red',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 15,
  },
}));
export const FooterLinks = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: 47,

  [theme.breakpoints.down('xl')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 7,
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 7,
  },
}));

export const FooterLink = styled('a')(() => ({
  color: COLORS.darkBlue,
  cursor: 'pointer',
}));

export const FooterLine = styled('div')(() => ({
  height: 1,
  width: '100%',
  background: COLORS.grey['50'],
  marginTop: 23,
}));

export const FooterBottomText = styled('div')(() => ({
  color: COLORS.grey['50'],
  marginTop: 23,
  textAlign: 'right',
}));
