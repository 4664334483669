import React from 'react';

import { StyledLink } from '@components/MobileDrawer/components/MobileDrawerMenu/styled';

import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages';

import {
  setIsTruckerAboutPopupOpen,
  setIsTruckerFaqPopupOpen,
  setIsTruckerHowItWorksPopupOpen,
} from '@store/features/app/app.slice';
import { setIsOpenContactUsPopupAction } from '@store/features/auth/auth.actions';
import { useRouter } from '@hooks/useRouter';
import { useAppDispatch } from '@store/hooks';

interface TruckerMenuLinksProps {
  onClose: () => void;
}

const TruckerMenuLinks = ({ onClose }: TruckerMenuLinksProps) => {
  const dispatch = useAppDispatch();

  const { navigate } = useRouter();

  return (
    <>
      <StyledLink
        onClick={() => {
          onClose();
          navigate(PAGE_ROUTES_PRIVATE.HOME);
        }}
      >
        Main Site
      </StyledLink>

      <StyledLink
        onClick={() => dispatch(setIsTruckerAboutPopupOpen(true))}
      >
        About Us
      </StyledLink>

      <StyledLink
        onClick={() => dispatch(setIsTruckerFaqPopupOpen(true))}
      >
        F.A.Q
      </StyledLink>

      <StyledLink
        onClick={() => dispatch(setIsTruckerHowItWorksPopupOpen(true))}
      >
        How MOO works
      </StyledLink>

      <StyledLink
        onClick={() => dispatch(setIsOpenContactUsPopupAction(true))}
      >
        Contact us
      </StyledLink>
    </>

  );
};

export default TruckerMenuLinks;
