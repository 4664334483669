import { FC, useMemo, useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ErrorMessage from '@components/ErrorMessage';
import { BurgerMenuButton } from '@components/AppBar/styled';
import { StyledErrorMessage } from '@components/ErrorMessage/styled';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import mooLogo from '@assets/svg/moo-login.svg';
import {
  StyledModal,
  StyledIconButton,
  StyledDialogContent,
  StyledModalTitle,
  StyledTextField,
  StyledLoginButton,
  StyledLogo, ResetPasswordLink,
} from './styled';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { LoginFormSchema } from '@pages/LoginMoal/schema';
import { COLORS } from '@utils/colors';
import { loginThunk } from '@store/features/auth/auth.thunks';
import { setIsOpenLoginPopupAction, setIsOpenResetPasswordPopupAction } from '@store/features/auth/auth.actions';

interface LoginModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  hideCloseButton?: boolean;
  hideResetPassword?: boolean;
  onLogin?: () => void;
}

interface ILoginFormValues {
  email: string;
  password: string;
}

const LoginModal: FC<LoginModalProps> = ({ isOpen, handleClose, hideCloseButton, hideResetPassword,
  onLogin }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const { isLoading, error } = useAppSelector((state) => state.auth.login);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation() as unknown as any;
  const navigate = useNavigate();

  const handleLogin: SubmitHandler<ILoginFormValues> = (data) => {
    dispatch(
      loginThunk({
        ...data,
        role: 'user',
      }),
    )
      .unwrap()
      .then(() => {
        if (handleClose) {
          handleClose();
        }

        reset();

        if (location?.state?.redirectUrl) {
          navigate(location.state.redirectUrl);
        }

        if (location?.state?.reload) {
          navigate(0);
        }

        if (onLogin) {
          onLogin();
        }
      });
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormValues>({
    resolver: yupResolver(LoginFormSchema),
  });

  const handleResetPassword = () => {
    dispatch(setIsOpenLoginPopupAction(false));
    dispatch(setIsOpenResetPasswordPopupAction(true));
  };

  const passwordInputProps = useMemo(
    () => ({
      endAdornment: showPassword ? (
        <VisibilityIcon
          cursor="pointer"
          color="action"
          onClick={() => setShowPassword(!showPassword)}
        />
      ) : (
        <VisibilityOffIcon
          cursor="pointer"
          color="action"
          onClick={() => setShowPassword(!showPassword)}
        />
      ),
    }),
    [showPassword],
  );

  return (
    <StyledModal keepMounted open={isOpen} onClose={handleClose}>
      {!hideCloseButton && (
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>
      )}

      <StyledDialogContent>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label="Log In"
          textAlign="center"
        />
        <Stack
          component="form"
          autoComplete="off"
          alignItems="center"
          onSubmit={handleSubmit(handleLogin)}
        >
          <StyledTextField
            placeholder="Your email address"
            variant="outlined"
            autoComplete="off"
            type="email"
            {...register('email', { value: '' })}
          />
          {errors.email && <ErrorMessage name="email" errors={errors} />}

          <StyledTextField
            placeholder="Your password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            InputProps={passwordInputProps}
            autoComplete="password"
            {...register('password', { value: '' })}
          />
          {errors.password && <ErrorMessage name="password" errors={errors} />}

          <StyledErrorMessage>{error}</StyledErrorMessage>

          <StyledLoginButton
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            Login
          </StyledLoginButton>

          {!hideResetPassword && (
          <ResetPasswordLink
            onClick={handleResetPassword}
          >
            Forgot password?
          </ResetPasswordLink>
          )}
        </Stack>

        <StyledLogo src={mooLogo} />
      </StyledDialogContent>
    </StyledModal>
  );
};

export default LoginModal;
