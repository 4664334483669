import { memo } from 'react';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';

import { StyledAppBar } from './styled';
import { useAppSelector } from '@store/hooks';
import LogoAndBurgerMenu from './components/LogoAndBurgerMenu';
import { useRouter } from '@hooks/useRouter';
import { IAppBarProps, renderAppBarContentByPahtname } from './helper';

const AppBar = ({ handleDrawerToggle }: IAppBarProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const {
    location: { pathname },
  } = useRouter();

  const roomDrawerWidth = useAppSelector(({ order }) => order.roomDrawerWidth);
  const isOpenRoomDrawer = useAppSelector(
    ({ order }) => order.isOpenRoomDrawer,
  );

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const isHomePage = pathname === '/';
  const isComingSoon = pathname === '/';
  const isCheckout = pathname === '/checkout';

  return (
    <StyledAppBar
      open={isOpenRoomDrawer}
      drawerWidth={roomDrawerWidth}
      trigger={!(isHomePage || isComingSoon) && trigger}
      elevation={0}
      position="fixed"
      className={clsx({ isCheckout, matches })}
    >
      <LogoAndBurgerMenu handleDrawerToggle={handleDrawerToggle} />
      {!matches &&
        renderAppBarContentByPahtname({
          handleDrawerToggle,
          isOpenRoomDrawer,
          pathname,
        })}
    </StyledAppBar>
  );
};

export default memo(AppBar);
