export const COLORS = {
  white: '#FFFFFF',
  beige: '#FEF6EB',
  orange: '#FFB49E',
  givry: '#F7EABC',
  morningGlory: '#82B5A5',
  amour: '#F8E6EB',
  amour2: '#F8E6EA',
  lightGreen: '#A6E7A9',
  monaLisa: '#FF9292',
  darkBlue: '#282750',
  yellowOG: '#FFCF47',
  black: '#000000',
  sugarCane: '#F7FFF7',
  waxFlower: '#FFBCA8',
  peachPuff: '#FFD4C4',
  portGore: '#282750',
  error: {
    main: '#FF0000',
  },
  grey: {
    50: '#A09D95', // main
    100: '#D4D3D3',
    200: '#F6F6F6',
    300: '#757575',
    400: '#EBEBEB', // dark
    500: '#E9E9E9',
    600: '#E5E5E5',
    700: '#EDEDED',
    800: '#f1f1f1',
    900: '#6A6A6A',
  },
  alpha: {
    denim008: 'red',
    black054: 'green',
    black087: 'blue',
  },
};

export type ColorsType = typeof COLORS;
