import { FC, useEffect } from 'react';

import { http } from 'api/RequestApi';
import axios from 'axios';

import StorageManager from '@utils/storage-manager';
import { BackErrorMessages, StorageKeysEnum } from '@utils/constants';
import AuthService from '@services/AuthService';
import { useAppDispatch } from '@store/hooks';
import { logoutThunk } from '@store/features/auth/auth.thunks';

// TODO: Must revoved after 'refreshCount' test
let refreshCount = 1;

const AxiosInterceptor: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const resInterceptor = (response: any) => {
      return response;
    };

    const errInterceptor = async (error: any) => {
      if (error?.data?.error === BackErrorMessages.INVALID_USER) {
        dispatch(logoutThunk());
      }

      // eslint-disable-next-line no-console
      console.error('Intercept error', { error });

      if (
        error.data.error === 'TokenExpiredError' && refreshCount <= 1
      ) {
        refreshCount += 1;

        const refreshToken = StorageManager.getItem(
          StorageKeysEnum.REFRESH_TOKEN,
          'local',
        );

        if (refreshToken) {
          try {
            const token = StorageManager.getItem(
              StorageKeysEnum.REFRESH_TOKEN,
              'local',
            );

            const { data } = await AuthService.refreshToken({ token });

            StorageManager.setItem(StorageKeysEnum.ACCESS_TOKEN, data.accessToken);
            StorageManager.setItem(StorageKeysEnum.REFRESH_TOKEN, data.refreshToken);

            const originalConfig = error.config;

            return axios.request({ ...originalConfig,
              headers: {
                ...originalConfig.headers, Authorization: `Bearer ${data.accessToken}`,
              } });
          } catch (e) {
            dispatch(logoutThunk());
          }
        }
      }

      return Promise.reject(error);
    };

    const interceptor = http.initHttp().interceptors.response.use(resInterceptor, errInterceptor);

    return () => http.initHttp().interceptors.response.eject(interceptor);
  }, [dispatch]);

  return (
    <>
      {children}
    </>
  );
};

export default AxiosInterceptor;
