import { useState } from 'react';

import { ITimerContext } from 'context/timer-context';
import moment from 'moment';

import { StorageKeysEnum } from '@utils/constants';
import StorageManager from '@utils/storage-manager';

const initialTime: string = StorageManager.getItem(StorageKeysEnum.SETUP_TIME) || '';

export const useTimeStamp = (): ITimerContext => {
  const [setupTime, setSetupTime] = useState<string>(initialTime);

  const clearTimeStamp = () => {
    setSetupTime('');
    StorageManager.removeItem(StorageKeysEnum.SETUP_TIME);
  };

  const createTimeStamp = () => {
    const currentDate = moment().format();

    StorageManager.setItem(StorageKeysEnum.SETUP_TIME, currentDate);
    setSetupTime(currentDate);
  };

  return ({ createTimeStamp, clearTimeStamp, setupTime });
};
