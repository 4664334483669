import { API_URL } from '@configs';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { StorageKeysEnum } from '@utils/constants';
import StorageManager from '@utils/storage-manager';
import ToastService from '@services/ToastService';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const token = StorageManager.getItem(StorageKeysEnum.ACCESS_TOKEN);

    const isAws = config.url?.includes('amazonaws.com');

    if (token != null && !isAws) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

const baseURL = `${API_URL}`;

// TODO: Must revoved after 'refreshCount' test
// const refreshCount = 1;

class RequestApi {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL,
      responseType: 'json' as const,
      withCredentials: false,
      headers,
    });

    http.interceptors.request.use(injectToken, (error) =>
      Promise.reject(error));

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;

        // TODO: implement refresh token through compnent for prevent reload, check if work then remove from here
        // if (response?.data?.error === 'TokenExpiredError' && refreshCount <= 1) {
        //   refreshCount += 1;
        //   const refreshToken = StorageManager.getItem(
        //     StorageKeysEnum.REFRESH_TOKEN,
        //     'local',
        //   );

        //   if (refreshToken) {
        //     AuthService.refreshToken({ token: refreshToken })
        //       .then(({ data }) => {
        //         if (data.accessToken && data.refreshToken) {
        //           StorageManager.setItem(StorageKeysEnum.ACCESS_TOKEN, data.accessToken);
        //           StorageManager.setItem(StorageKeysEnum.REFRESH_TOKEN, data.refreshToken);
        //           // TODO: with 'store.dispatch' give init action issue
        //           // store.dispatch(getUserInfoThunk());
        //           window.location.reload();
        //         }
        //       })
        //       .catch((e) => {
        //         // TODO: Must removed after test
        //         // eslint-disable-next-line no-alert
        //         const errorDetails = (e as ErrorData)?.data?.details;

        //         if (errorDetails) {
        //           errorDetails.forEach((ed) => {
        //             ToastService.error((ed as any).errorName);
        //           });
        //         }
        //       });
        //   }
        // }

        return RequestApi.handleError(response);
      },
    );

    this.instance = http;

    return http;
  }

  request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private static handleError = (error: any) => {
    // const { status = null } = error;
    const status = error?.status;

    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        ToastService.error('InternalServerError');
        break;
      }

      case StatusCode.Forbidden: {
        // Handle Forbidden
        ToastService.error('Forbidden');
        break;
      }

      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        // ToastService.error('Unauthorized');
        break;
      }

      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        ToastService.error('TooManyRequests');
        break;
      }

      default:
        null;
    }

    return Promise.reject(error);
  };
}

export const http = new RequestApi();
