import { memo } from 'react';

import { ErrorMessage as HookFormErrorMessage, Props } from '@hookform/error-message';

import { StyledErrorMessage } from './styled';

interface IErrorMessageProps extends Props<any, any> {}

const ErrorMessage = ({ errors, name, ...restProps }: IErrorMessageProps) => {
  return (
    <HookFormErrorMessage
      errors={errors}
      name={name}
      render={({ message }) =>
        <StyledErrorMessage>{message}</StyledErrorMessage>}
      {...restProps}
    />
  );
};

export default memo(ErrorMessage);
