import React, { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { getCurrentStepMooveThunk } from '@store/features/order/order.thunks';
import { getRouteByStep } from '@utils/global';
import { useAppDispatch } from '@store/hooks';
import { setHashAction } from '@store/features/order/order.actions';
import AuthService from '@services/AuthService';
import LoginModal from '@pages/LoginMoal';
import ToastService from '@services/ToastService';

const ContinueMove = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const hash = searchParams.get('hash') as string;
  const path = searchParams.get('path') as string;

  const handleLogin = () => {
    dispatch(setHashAction(hash));
    navigate(`/${path}`);
  };

  useEffect(() => {
    (async () => {
      AuthService.getProfile().then(() => {
        dispatch(getCurrentStepMooveThunk({ hash }))
          .unwrap()
          .then(({ data }) => {
            const currentRoute = getRouteByStep(data.step);

            dispatch(setHashAction(hash));

            navigate(currentRoute);
          }).catch(() => {
            ToastService.error('Something went wrong or move does not exist');
          });
      })
        .catch(() => {
          setShowLoginModal(true);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showLoginModal ? (
        <LoginModal
          hideCloseButton
          hideResetPassword
          isOpen={showLoginModal}
          handleClose={() => {}}
          onLogin={handleLogin}
        />
      ) : (
        <Backdrop
          open
          sx={{ color: '#fff' }}
        >
          <Stack gap={3} alignItems="center">
            <CircularProgress color="inherit" />
            <Typography fontSize="20px">Loading, please wait...</Typography>
          </Stack>
        </Backdrop>
      )}
    </>

  );
};

export default ContinueMove;
