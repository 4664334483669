import { ReactNode, useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ErrorMessage from '@components/ErrorMessage';
import { StyledErrorMessage } from '@components/ErrorMessage/styled';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLocation } from 'react-router-dom';

import { StyledTextField } from '@pages/LoginMoal/styled';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { registerThunk } from '@store/features/auth/auth.thunks';
import {
  StyledRegisterBackground,
  StyledPhoneNumber,
} from '@pages/Register/styled';
import { RegisterFormSchema } from '@pages/Register/schema';
import { IRegisterFormValues } from './helper';

interface IRegisterForm {
  actions?: ReactNode;
  fromProgress?: boolean;
  instate?: boolean;
  onRegister?: () => void;
}

const RegisterForm = ({ actions, fromProgress, onRegister, instate }: IRegisterForm) => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const { isLoading, error } = useAppSelector(({ auth }) => auth.register);
  const hash = useAppSelector(({ order }) => order.hash);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IRegisterFormValues>({
    resolver: yupResolver(RegisterFormSchema),
  });

  const handleRegister: SubmitHandler<IRegisterFormValues> = (data) => {
    dispatch(
      registerThunk({
        ...data,
        confirmPassword: data.password,
        fromProgress,
        instate,
        path: pathname.replaceAll('/', ''),
        hash,
      }),
    )
      .unwrap()
      .then(() => {
        reset();

        if (onRegister) {
          onRegister();
        }
      });
  };

  const getInputProps = (show: boolean, change: (state: boolean) => void) => ({
    autoComplete: 'off',
    form: {
      autoComplete: 'new-password',
    },
    endAdornment: show ? (
      <VisibilityIcon
        cursor="pointer"
        color="action"
        onClick={() => change(!show)}
      />
    ) : (
      <VisibilityOffIcon
        cursor="pointer"
        color="action"
        onClick={() => change(!show)}
      />
    ),
  });

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(handleRegister)}
    >
      <Stack alignItems="center">
        <StyledTextField
          id="userName"
          placeholder="Your Name"
          variant="outlined"
          type="text"
          autoComplete="off"
          {...register('username')}
        />
        {errors.username && <ErrorMessage name="username" errors={errors} />}

        <StyledTextField
          id="userEmail"
          placeholder="Your Email Address"
          variant="outlined"
          type="email"
          autoComplete="email"
          {...register('email', {
            value: '',
          })}
        />
        {errors.email && <ErrorMessage name="email" errors={errors} />}

        <StyledTextField
          placeholder="Your Password"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          id="password"
          {...register('password', {
            value: '',
          })}
          InputProps={getInputProps(showPassword, setShowPassword)}
        />
        {/* TODO: Must diacle autocomplete of browser -> sitll not working */}

        {errors.password && <ErrorMessage name="password" errors={errors} />}

        <StyledTextField
          placeholder="Password confirmation"
          variant="outlined"
          type={showPasswordConfirmation ? 'text' : 'password'}
          id="confirmPassword"
          {...register('confirmPassword', {
            value: '',
          })}
          InputProps={getInputProps(showPasswordConfirmation, setShowPasswordConfirmation)}

        />
        {errors.confirmPassword && (
          <ErrorMessage name="confirmPassword" errors={errors} />
        )}

        <StyledPhoneNumber
          country="us"
          onlyCountries={['us']}
          disableCountryCode
          placeholder="Your Phone Number"
          onChange={(val: any) => setValue('phone', val)}
        />
        {errors.phone && <ErrorMessage name="phone" errors={errors} />}

        <StyledErrorMessage>{error}</StyledErrorMessage>

        {actions || (
          <StyledRegisterBackground
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            Register
          </StyledRegisterBackground>
        )}
      </Stack>
    </Box>
  );
};

export default RegisterForm;
