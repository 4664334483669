import { memo, useMemo } from 'react';

import { STEPPER_HEIGHT } from '@components/Stepper/helper';
import { StepIconProps } from '@mui/material/StepIcon';
import { ColorlibStepIconRoot } from '@components/Stepper/StepIcon/styled';
import Typography from '@mui/material/Typography';

import { MoveStepsEnum } from '@appTypes/enums/steps';

import { useAppSelector } from '@store/hooks';
import { ColorlibConnector, StepperCenterItemBox, StyledMuiStep, StyledMuiStepLabel, StyledMuiStepper } from './styled';
import addressIcon from '@assets/svg/stepper/address.svg';
import dateIcon from '@assets/svg/stepper/date.svg';
import boxesIcon from '@assets/svg/stepper/boxes.svg';
import inventoryIcon from '@assets/svg/stepper/inventory.svg';
import quoteIcon from '@assets/svg/stepper/quote.svg';

const steps = [
  {
    label: 'Address',
    icon: addressIcon,
  },
  {
    label: 'Date',
    icon: dateIcon,
  },
  {
    label: 'Boxes',
    icon: boxesIcon,
  },
  {
    label: 'Inventory',
    icon: inventoryIcon,
  },
  {
    label: 'Your Quote!',
    icon: quoteIcon,
  },
];

const ImageIcon = ({ src }: { src: string }) => (
  <img src={src} alt="" />
);

const DesktopStepper = () => {
  const activeStep = useAppSelector(({ order }) => order.activeStep);

  const currentActiveStep = useMemo(() => {
    switch (activeStep) {
      case MoveStepsEnum.DATE:
        return 1;
      case MoveStepsEnum.HOUSE:
      case MoveStepsEnum.PEOPLE:
        return 2;
      case MoveStepsEnum.ROOM:
        return 3;
      case MoveStepsEnum.INVENTORY:
        return 3;
      case MoveStepsEnum.INVENTORY_REVIEW:
        return 3;
      case 8:
        return 4;
      default:
        return 0;
    }
  }, [activeStep]);

  return (
    <StepperCenterItemBox>
      <StyledMuiStepper
        sx={{ height: `${STEPPER_HEIGHT}px` }}
        activeStep={currentActiveStep}
        connector={<ColorlibConnector />}
      >
        {steps.map(({ icon, label }, index) => (
          <StyledMuiStep key={label}>
            <StyledMuiStepLabel
              StepIconComponent={Icon}
              StepIconProps={{
                icon: <ImageIcon src={icon} />,
              }}
            >
              <Typography
                fontSize="16px"
                marginTop="5px"
                fontWeight={index === steps.length - 1 ? 'bold' : 'normal'}
              >
                {label}
              </Typography>
            </StyledMuiStepLabel>
          </StyledMuiStep>
        ))}
      </StyledMuiStepper>
    </StepperCenterItemBox>
  );
};

const Icon = ({
  active,
  completed,
  className,
  icon,
}: StepIconProps) => (
  <ColorlibStepIconRoot
    ownerState={{ completed, active }}
    className={className}
  >
    {icon}
  </ColorlibStepIconRoot>
);

export default memo(DesktopStepper);
