import Button from '@components/Button';
import Typography from '@components/Typography';
import Stack from '@mui/material/Stack';

import { useRouter } from '@hooks/useRouter';

const NotFound = () => {
  const { navigate } = useRouter();

  return (
    <Stack spacing={4} sx={{ maxWidth: '200px' }}>
      <Typography variant="h3">Not Found</Typography>
      <Button
        sx={{
          padding: '12px 30px',
          maxHeight: '44px',
        }}
        onClick={() => navigate('/')}
      >
        Go to Home
      </Button>
    </Stack>
  );
};

export default NotFound;
