import { FC } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import { BurgerMenuButton } from '@components/AppBar/styled';
import { UseFormReset } from 'react-hook-form';

import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import mooLeftBackground from '@assets/svg/moo-register-background-left.svg';
import mooRightBackground from '@assets/svg/moo-register-background-right.svg';
import mooMobileBackground from '@assets/svg/moo-register-mobile-background.svg';
import {
  StyledModal,
  StyledIconButton,
} from '@pages/LoginMoal/styled';
import {
  StyledModalTitle, StyledModalDescription,
  StyledModalBottomText, StyledDialogContent,
  StyledLeftBackground, StyledRightBackground, StyledMobileBackground, StyleContent,
} from '@pages/Register/styled';
import { COLORS } from '@utils/colors';
import { IRegisterFormValues } from './helper';
import RegisterForm from './RegisterForm';

interface RegisterProps {
    isOpen: boolean;
    handleClose: () => void;
    onLogin: () => void;
}

const Register: FC<RegisterProps> = ({ isOpen, handleClose, onLogin }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCloseModal = (reset?: UseFormReset<IRegisterFormValues>) => {
    reset?.();
    handleClose();
  };

  return (
    <StyledModal keepMounted open={isOpen} onClose={() => handleCloseModal()}>
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}

      </DialogTitle>

      <StyledDialogContent>
        <StyleContent>
          <StyledModalTitle
            component="h3"
            variant="h1"
            label="Welcome to Moo Moves You!"
            textAlign="center"
          />

          <StyledModalDescription>
            Register to save your info and quote
          </StyledModalDescription>

          <RegisterForm onRegister={handleCloseModal} />

          <StyledModalBottomText>
            Already have an account?
            <span onClick={onLogin}>Log In</span>
          </StyledModalBottomText>

          <StyledLeftBackground src={mooLeftBackground} />
          <StyledRightBackground src={mooRightBackground} />
        </StyleContent>
        <StyledMobileBackground src={mooMobileBackground} />
      </StyledDialogContent>
    </StyledModal>
  );
};

export default Register;
