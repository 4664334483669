export const questions = [
  {
    text: 'Can anyone sign up to be a trucker with Moo Moves You?',
    answer: 'Yes, as long as you are a registered Department of Transportation Moving Company, have a ' +
        'satisfactory SAFER rating and have the required insurance, you can sign up to be a Moo Trucker Partner!',
  },
  {
    text: 'How much does it cost to select jobs?',
    answer: 'Each job you select is only $49.00! Stop paying for leads, only pay for confirmed jobs!',
  },
  {
    text: 'What happens if a job cancels after it has been selected?',
    answer: 'If one of our customers cancels a job after you have selected it from the job board, you are ' +
        'returned your $49.00 job fee and given a free job credit so that the next job you choose is on us!',
  },
  {
    text: 'How do I find jobs with Moo Moves You?',
    answer: 'Once you are approved as a Moo Trucker Partner, you receive a login to access our Digital Load Board. ' +
        'All available, confirmed jobs are listed, you can filter for easy searches and then choose your jobs with ' +
        'the click of a mouse!',
  },
  {
    text: 'How do I sign up to be a Moo Mover?',
    answer: 'To sign up, please fill out your application at (insert website or hyperlink) ',
  },
  {
    text: 'How do I get paid for moves?',
    answer: 'All Moo Moves You customers are aware that 50% of their moving fee is due prior to loading and the ' +
        'remaining 50% is due prior to unloading. After you choose a job from the Digital Load Board, you will be ' +
        'in direct contact with your customer and you can organize both payments directly with them, just as you ' +
        'would with any of your other customers!',
  },
  {
    text: 'Do I need a Department of Transportation (DOT) number to be a Moo Mover?',
    answer: 'Yes',
  },
  {
    text: 'Do I need a Motor Carrier (MC) number to be a Moo Mover?',
    answer: 'Yes',
  },
  {
    text: 'Is there a limit to the number of moves I can do?',
    answer: 'No, all of the jobs on our Digital Load Board are available to all Moo Trucker Partners and they ' +
        'are first come, first serve! All you have to do is pay $49.00 per job and adhere to the terms and ' +
        'conditions of our Moo Trucker Partner agreement!',
  },
  {
    text: 'What Information do I receive on the Digital Load Board prior to accepting a job?',
    answer: 'On our Digital Load Board, you will see: ' +
        '<br />■ Pickup location' +
        '<br />■ Dropoff location' +
        '<br />■ Requested pickup date(s)' +
        '<br />■ Total amount of cubic feet of the move (furniture and boxes)' +
        '<br />■ Total price you will be paid for this move',
  },
  {
    text: 'What happens if I get to a move site and it is a bigger move than initially stated?',
    answer: 'All Moo Moves You customers are aware that the Binding Estimate they receive is only for the furniture' +
        ' items listed on their inventory list and the associated boxes that are included in their move. Anything ' +
        'over and above what is on this inventory list is not included and you will bill them additionally for' +
        ' these items along with any additional services they agree to or are required to complete the move based ' +
        'on our Master Service Agreement. You receive a full copy of the inventory list in your admin panel after' +
        ' you agree to the move. The cost for additional items and/or services will be billed directly by you to ' +
        'the customer prior to loading at the same cost per cubic foot as the rest of the inventory.',
  },
  {
    text: 'What happens if a Moo Trucker Partner has to cancel a move?',
    answer: 'If a Moo Trucker Partner has to cancel a job for any reason, we ask that they let us know no later ' +
        'than 7 days prior to the scheduled pickup date(s). If canceled with less than 7 days notice, there will ' +
        'be a cancellation fee. If a Moo Trucker Partner has a history of canceling jobs, Moo Moves You may choose ' +
        'to suspend or cancel your access to our Digital Load Board.',
  },
];
