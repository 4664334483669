import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Box from '@mui/material/Box';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Typography } from '@mui/material';

import { COLORS } from '@utils/colors';

export const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 'unset',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#82B5A5',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#82B5A5',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    width: '70%',
    margin: '36px auto 0 auto',
    backgroundColor: COLORS.grey['50'],
  },
}));

export const StepperCenterItemBox = styled(Box)(({ theme }) => ({
  // maxWidth: 544,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '10px',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledMuiStep = styled(Step)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const StyledMuiStepLabel = styled(StepLabel)(() => ({
  '&.MuiStepLabel-root': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .MuiStepLabel-label': {
    opacity: 0.5,
  },

  '& .Mui-completed, & .Mui-active': {
    opacity: 1,
  },

  '& .MuiStepLabel-iconContainer': {
    padding: 0,
  },

}));

export const StyledMuiStepper = styled(Stepper)(() => ({
  marginTop: '20px',
  maxWidth: '544px',
  width: '100%',
}));

export const MoveStepText = styled(Typography)(({ theme }) => ({
  margin: '3px 0',
  color: COLORS.darkBlue,

  [theme.breakpoints.down('xl')]: {
    margin: 0,
  },
}));
