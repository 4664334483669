import { lazy } from 'react';

import Layout from '@containers/Layout';
import PrivacyLayout from '@containers/PolicyLayout';
import { Navigate } from 'react-router-dom';

import type { RouteObject } from 'react-router-dom';
import { PAGE_ROUTES_PRIVATE, PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages';

import NotFound from '@pages/NotFound';
import { lazyRetry } from '@utils/retry';
import ContinueMove from '@pages/ContinueMove';
import HowItWorks from '@pages/HowItWorks';

const Home = lazy(() => lazyRetry(() => import('@pages/Home')));
const Room = lazy(() => lazyRetry(() => import('@pages/Room')));
const InventoryReview = lazy(() => lazyRetry(() => import('@pages/InventoryReview')));
const People = lazy(() => lazyRetry(() => import('@pages/People')));
const Inventory = lazy(() => lazyRetry(() => import('@pages/Inventory')));
const House = lazy(() => lazyRetry(() => import('@pages/House')));
const Date = lazy(() => lazyRetry(() => import('@pages/Date')));
const Quote = lazy(() => lazyRetry(() => import('@pages/Quote')));
const Checkout = lazy(() => lazyRetry(() => import('@pages/Checkout')));
const MyProfile = lazy(() => lazyRetry(() => import('@pages/MyProfile')));
const TruckerLanding = lazy(() => lazyRetry(() => import('@pages/TruckerLanding')));
const TruckerBecomePartner = lazy(() => lazyRetry(() => import('@pages/TruckerBecomePartner')));
const SetNewPassword = lazy(() => lazyRetry(() => import('@pages/SetNewPassword')));
const PrivacyPolicy = lazy(() => lazyRetry(() => import('@pages/PrivacyPolicy')));
const TermsAndConditions = lazy(() => lazyRetry(() => import('@pages/TermsAndConditions')));
const CookiesPolicy = lazy(() => lazyRetry(() => import('@pages/CookiesPolicy')));
const AboutUs = lazy(() => lazyRetry(() => import('@pages/AboutUs')));
const Faq = lazy(() => lazyRetry(() => import('@pages/FaqPopup')));
const Affiliate = lazy(() => lazyRetry(() => import('@pages/Affiliate')));
const AffiliateBecomePartner = lazy(() => lazyRetry(() => import('@pages/AffiliateBecomePartner')));

export const ROUTES: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PAGE_ROUTES_PRIVATE.COMING_SOON,
        element: <Home />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.TRUCKER_LANDING,
        element: <TruckerLanding />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.TRUCKER_BECOME_PARTNER,
        element: <TruckerBecomePartner />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.HOME,
        element: <Home />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.LOCATION,
        element: <Navigate to={PAGE_ROUTES_PRIVATE.HOME} />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.DATE,
        element: <Date />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.HOUSE,
        element: <House />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.ROOM,
        element: <Room />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.INVENTORY,
        element: <Inventory />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.PEOPLE,
        element: <People />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.INVENTORY_REVIEW,
        element: <InventoryReview />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.QUOTE,
        element: <Quote />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.INSURANCE,
        element: <Navigate to={PAGE_ROUTES_PRIVATE.QUOTE} />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.CHECKOUT,
        element: <Checkout />,
      },
      {
        path: PAGE_ROUTES_PRIVATE.MY_PROFILE,
        element: <MyProfile />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.ABOUT,
        element: <AboutUs />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.FAQ,
        element: <Faq />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.HOW_IT_WORKS,
        element: <HowItWorks />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.AFFILIATE,
        element: <Affiliate />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.AFFILIATE_BECOME_PARTNER,
        element: <AffiliateBecomePartner />,
      },
      {
        path: '/404',
        element: <NotFound />,
      },
    ],
  },
  {
    path: PAGE_ROUTES_PUBLIC.SET_NEW_PASSWORD,
    element: <SetNewPassword />,
  },
  {
    element: <PrivacyLayout />,
    children: [
      {
        path: PAGE_ROUTES_PUBLIC.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.TERMS_AND_CONDITIONS,
        element: <TermsAndConditions />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.COOKIES_POLICY,
        element: <CookiesPolicy />,
      },
    ],
  },
  {
    path: PAGE_ROUTES_PUBLIC.CONTINUE_MOVE,
    element: <ContinueMove />,
  },
];
