import { Components } from '@mui/material/styles';

import { COLORS } from '@utils/colors';

export const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    disableRipple: true, // NOTE: disable if you didn't wont click effect effect
  },
  styleOverrides: {
    root: {
      color: COLORS.darkBlue,
    },
  },
};

export const MuiButtonBase: Components['MuiButtonBase'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      color: COLORS.darkBlue,
    },
    disabled: {},
    focusVisible: {},
  },
};
