import { BurgerMenuButton, StyledMobileStack } from '@components/AppBar/styled';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import PlainImage from '@components/Image/PlainImage';
import { IAppBarProps } from '@components/AppBar/helper';

import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages';

import { ReactComponent as MobileBurgerMenuIcon } from '@assets/svg/mobile-burger-menu-icon.svg';
import appLogo from '@assets/svg/app-logo-mobile.svg';
import { useAppSelector } from '@store/hooks';
import { StyledMobileLogo } from '../../styled';

interface ILogoAndBurgerMenuProps extends IAppBarProps {}

const LogoAndBurgerMenu = ({ handleDrawerToggle }: ILogoAndBurgerMenuProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isOpenRoomDrawer = useAppSelector(({ order }) => order.isOpenRoomDrawer);

  const isHomePage = pathname === '/';
  const isComingSoon = pathname === '/';

  const handleLogoImageClick = () => {
    navigate(PAGE_ROUTES_PRIVATE.COMING_SOON);
  };

  return (
    <>
      {matches && !isOpenRoomDrawer && (
        <StyledMobileStack>
          <StyledMobileLogo>
            {!isHomePage && !isComingSoon && (
              <PlainImage
                src={appLogo}
                alt="Moo've"
                onClick={handleLogoImageClick}
              />
            )}
          </StyledMobileLogo>
          <BurgerMenuButton onClick={handleDrawerToggle} disableFocusRipple>
            <MobileBurgerMenuIcon />
          </BurgerMenuButton>
        </StyledMobileStack>
      )}
    </>
  );
};

export default LogoAndBurgerMenu;
