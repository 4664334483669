import { FC, memo } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import {
  HowItWorkText as Title,
  StyledDialogContent,
  StyledIconButton,
  StyledModal,
} from '@pages/HowItWorks/styled';
import { QuestionsStack, QuestionBlock, QuestionTitle, QuestionDescription, Content } from '@pages/FaqPopup/styled';
import { questions } from './helper';

interface TruckerFaqPopupProps {
    isOpen: boolean;
    onClose: () => void;
}

const TruckerFaqPopup: FC<TruckerFaqPopupProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledModal keepMounted open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ padding: 0 }}>
        <StyledIconButton
          aria-label="close"
          onTouchMove={onClose}
          onClick={onClose}
        >
          {matches ? <MobileCloseIcon /> : <CloseIcon />}
        </StyledIconButton>
      </DialogTitle>

      <StyledDialogContent>
        <Content>
          <Title
            component="h3"
            variant="h1"
            label="FAQs"
          />
          <QuestionsStack>
            {questions.map((question) => (
              <QuestionBlock key={question.text}>
                <QuestionTitle>{question.text}</QuestionTitle>
                <QuestionDescription
                  dangerouslySetInnerHTML={{
                    __html: question.answer,
                  }}
                />
              </QuestionBlock>
            ))}
          </QuestionsStack>
        </Content>
      </StyledDialogContent>
    </StyledModal>
  );
};

export default memo(TruckerFaqPopup);
