import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import { MoveStepsEnum } from '@appTypes/enums/steps';
import {
  CustomRoomModel,
  RoomInventories,
  RoomModel,
} from '@appTypes/model/room.model';
import { HouseModel } from '@appTypes/model/house.model';

import { ROOM_DRAWER_WIDTH_WEB, StorageKeysEnum } from '@utils/constants';
import { OrderState } from './types';
import {
  createDateMooveThunk,
  createInitMooveThunk,
  createPeopleMooveThunk,
  getCalcPriceMooveThunk,
  getCurrentStepMooveThunk,
  getMoveInfoThunk,
  getRoomsThunk,
} from './order.thunks';
import StorageManager from '@utils/storage-manager';
import { getCurrentStepMoveState } from './helper';

const sessionOrderState: OrderState | undefined = StorageManager.getItem(
  StorageKeysEnum.ORDER,
  'session',
);

const initialState: OrderState = {
  activeStep: MoveStepsEnum.INIT,
  isOpenRoomDrawer: false,
  isDisableNextStep: false,
  isSavedInfo: false,
  isSavedQuoteInfo: false,
  isOpenInventoryReviewPopup: false,
  isOpenSaveProgressPopup: false,
  isOpenSameStatePopup: false,
  roomDrawerWidth: ROOM_DRAWER_WIDTH_WEB,
  selectedRooms: [],
  selectedRoomId: '',
  selectedRoomIds: [],
  signature: null,
  quote: null,
  isConfirmedInventoryReview: false,
  hash: StorageManager.getItem(StorageKeysEnum.MOVE_HASH),
  location: null,
  fromDate: null,
  toDate: null,
  nextRoomIndex: 0,
  isExactDate: false,
  proKitchen: false,
  selectedHouse: null,
  rooms: [],
  roomsCount: 0,
  countPeople: 1,
  insuranceId: '',
  token: '',
  ip: '',
  cardId: '',
  errors: null,
  moveAndPriceInfo: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState: sessionOrderState || initialState,
  reducers: {
    setResetMove: () => {
      StorageManager.removeItem(StorageKeysEnum.MOVE_HASH);
      StorageManager.removeItem(StorageKeysEnum.ORDER);

      return {
        ...initialState,
        hash: null,
        location: null,
      };
    },
    setHash: (state, { payload }: PayloadAction<string>) => {
      StorageManager.setItem(StorageKeysEnum.MOVE_HASH, payload);

      state.hash = payload;
    },
    setIsOpenInventoryReviewPopup: (
      state,
      action: PayloadAction<OrderState['isOpenInventoryReviewPopup']>,
    ) => {
      state.isOpenInventoryReviewPopup = action.payload;
    },
    setIsOpenSaveProgressPopupOpen: (
      state,
      action: PayloadAction<OrderState['isOpenSaveProgressPopup']>,
    ) => {
      state.isOpenSaveProgressPopup = action.payload;
    },
    setIsOpenSameStatePopup: (
      state,
      action: PayloadAction<OrderState['isOpenSameStatePopup']>,
    ) => {
      state.isOpenSameStatePopup = action.payload;
    },
    setToken: (state, action: PayloadAction<OrderState['token']>) => {
      state.token = action.payload;
    },
    setCardId: (state, action: PayloadAction<OrderState['cardId']>) => {
      state.cardId = action.payload;
    },
    setHoueRooms: (
      state,
      action: PayloadAction<OrderState['selectedHouse']>,
    ) => {
      state.selectedHouse = action.payload;
    },
    setIsConfirmedInventoryReview: (
      state,
      action: PayloadAction<OrderState['isConfirmedInventoryReview']>,
    ) => {
      state.isConfirmedInventoryReview = action.payload;
    },
    setInsuranceId: (
      state,
      action: PayloadAction<OrderState['insuranceId']>,
    ) => {
      state.insuranceId = action.payload;
    },
    setAllRooms: (state, action: PayloadAction<OrderState['rooms']>) => {
      state.rooms = action.payload;
    },
    setLocation: (state, action: PayloadAction<OrderState['location']>) => {
      state.location = {
        ...state.location,
        ...action.payload,
      };
    },
    setDate: (
      state,
      {
        payload,
      }: PayloadAction<{ fromDate?: Date; toDate?: Date; exactDate?: Date }>,
    ) => {
      state.fromDate = payload?.fromDate;
      state.toDate = payload?.toDate;
      state.exactDate = payload?.exactDate;
    },
    setActiveStep: (state, action: PayloadAction<OrderState['activeStep']>) => {
      state.activeStep = action.payload;
    },
    setIsDisableNextStep: (
      state,
      action: PayloadAction<OrderState['isDisableNextStep']>,
    ) => {
      state.isDisableNextStep = action.payload;
    },
    setIsSavedInfo: (
      state,
      action: PayloadAction<OrderState['isSavedInfo']>,
    ) => {
      state.isSavedInfo = action.payload;
    },
    setIsSavedQuoteInfo: (
      state,
      action: PayloadAction<OrderState['isSavedInfo']>,
    ) => {
      state.isSavedQuoteInfo = action.payload;
    },
    setIsOpenRoomDrawer: (
      state,
      action: PayloadAction<OrderState['isOpenRoomDrawer']>,
    ) => {
      state.isOpenRoomDrawer = action.payload;
    },
    setRoomDrawerWidth: (
      state,
      action: PayloadAction<OrderState['roomDrawerWidth']>,
    ) => {
      state.roomDrawerWidth = action.payload;
    },
    setRoomItem: (
      state,
      { payload }: PayloadAction<OrderState['rooms'][0]>,
    ) => {
      const { id, count = 0 } = payload;

      state.rooms = state.rooms.map((room) =>
        (room.id === id ? { ...room, count } : room));
    },
    setRoomInventories: (
      state,
      { payload }: PayloadAction<{ roomInventories: RoomInventories[] }>,
    ) => {
      const { roomInventories } = payload;
      const selectedRoom = state.selectedRooms.find(
        (i) => i.id === state.selectedRoomId,
      );

      const selectedRoomId = selectedRoom?.id;
      const newRooms = state.rooms.map((i) =>
        (i.id === selectedRoomId
          ? {
            ...i,
            roomInventories,
          }
          : i));

      state.rooms = newRooms;
    },
    setSelectedRoomId: (state, action: PayloadAction<string>) => {
      state.selectedRoomId = action.payload;
    },
    selectedRoomIds: (state, action: PayloadAction<string[]>) => {
      state.selectedRoomIds = action.payload;
    },
    setSelectedRooms: (state, action: PayloadAction<RoomModel[]>) => {
      state.selectedRooms = action.payload;
    },
    setSelectedHouse: (state, action: PayloadAction<HouseModel>) => {
      state.selectedHouse = action.payload;

      action.payload?.houseRooms?.forEach((el) => {
        state.rooms = state.rooms.map((r) =>
          (r.id === el.room.id ? { ...r, count: el.count } : r));
      });
    },
    setCountPeople: (state, action: PayloadAction<number>) => {
      state.countPeople = action.payload;
    },
    setProKitchen: (state, action: PayloadAction<boolean>) => {
      state.proKitchen = action.payload;
    },
    setExactDate: (state, action: PayloadAction<boolean>) => {
      state.isExactDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    /** MOOVES GET CURRENT STEP */
    builder.addCase(
      getCurrentStepMooveThunk.fulfilled,
      (state, { payload }) => {
        const { data } = payload;

        getCurrentStepMoveState({ data, state });
      },
    );

    /** MOOVES INIT */
    builder.addCase(createInitMooveThunk.fulfilled, (state, { payload }) => {
      const {
        data: { hash },
      } = payload;

      StorageManager.setItem(StorageKeysEnum.MOVE_HASH, hash);
      state.hash = hash;
      state.activeStep = MoveStepsEnum.LOCATION;
    });

    /** DATE */
    builder.addCase(createDateMooveThunk.fulfilled, (state) => {
      state.activeStep = MoveStepsEnum.DATE + 1;
    });

    /** ROOMS */
    builder.addCase(getRoomsThunk.fulfilled, (state, { payload }) => {
      const houseRooms = state.selectedHouse?.houseRooms;

      const newRooms = payload.data.map((i) => {
        const foundItem = houseRooms?.find((j) => j.room?.id === i?.id);

        return foundItem
          ? ({
            ...i,
            count: foundItem.count,
          } as RoomModel)
          : i;
      });

      state.rooms = newRooms;
    });

    /** COUNT PEOPLE */
    builder.addCase(createPeopleMooveThunk.rejected, (state) => {
      state.isDisableNextStep = true;
    });

    /** CALC PRICE */
    builder.addCase(getCalcPriceMooveThunk.fulfilled, (state, { payload }) => {
      const { data } = payload;

      const { costs, payments, total } = data;

      state.quote = {
        costs,
        payments,
        total,
      };
    });

    builder.addCase(getMoveInfoThunk.fulfilled, (state, { payload }) => {
      state.moveAndPriceInfo = payload.data;

      const { data } = payload ?? {};
      const { move, price } = data ?? {};

      if (move) {
        const { house, exactDate, moveRooms } = move;

        // # Rooms
        const createdRoooms = moveRooms.length;

        if (createdRoooms && state.rooms.length) {
          const newRooms = state.rooms.map((i) => {
            const foundElem = moveRooms.find(
              (moveRoom) => moveRoom.room.id === i.id,
            );

            return (foundElem) ? { ...i, count: foundElem.count, isConfirmed: true } as CustomRoomModel : i;
          });

          state.rooms = newRooms;
        }

        // TODO: in back must add "roomInventories" field
        //         if (moveRooms.length && !state.rooms.length) {
        //           const mapedMovesRooms = moveRooms.map((i) => ({
        // id: i.room.id,
        // image: i.room.image,
        // roomInventories
        //           } as CustomRoomModel))
        //         }

        // # selectedHouse
        state.selectedHouse = { ...state.selectedHouse, ...house };

        // # ecactDate
        if (exactDate) {
          state.exactDate = exactDate;
          state.isExactDate = true;
        }

        if (move.toDate && move.fromDate) {
          state.toDate = move.toDate;
          state.fromDate = move.fromDate;
          state.isExactDate = false;
        }

        // # People
        if (move.countPeople) {
          state.countPeople = move.countPeople;
        }

        // # Location
        if (move?.fromCoordinates && move?.fromCoordinates) {
          const { x: fromX, y: fromY } = move.fromCoordinates;
          const { x: toX, y: toY } = move.toCoordinates;
          const distance = move?.distance;
          const fromAddress = move?.fromLocation;
          const toAddress = move?.toLocation;
          const fromState = fromAddress.split(',')[2].trim();
          const toState = toAddress.split(',')[2].trim();

          state.location = {
            fromLocation: {
              latitude: `${fromX}`,
              longitude: `${fromY}`,
              address: fromAddress,
              state: fromState,
            },
            toLocation: {
              latitude: `${toX}`,
              longitude: `${toY}`,
              address: toAddress,
              state: toState,
            },
            distance,
          };
        }
      }

      if (price) {
        state.quote = price;
      }
    });
  },
});

export default orderSlice.reducer;
