export enum PAGE_ROUTES_PRIVATE {
  HOME = '/',
  LOCATION = '/location',
  DATE = '/date',
  HOUSE = '/house',
  ROOM = '/room',
  INVENTORY = '/inventory',
  PEOPLE = '/people',
  INVENTORY_REVIEW = '/inventory-review',
  QUOTE = '/quote',
  INSURANCE = '/insurance',
  CHECKOUT = '/checkout',
  MY_PROFILE = '/my-profile',
  TRUCKER_LANDING = '/trucker-landing',
  TRUCKER_BECOME_PARTNER = '/trucker-become-partner',
  COMING_SOON = '/',
}

export enum PAGE_ROUTES_PUBLIC {
  ROOT_SIGN_IN = '/',
  SIGN_IN = '/sign-in',
  FORGOT_PASSWORD = '/forgot-password',
  SET_NEW_PASSWORD = '/set-new-password',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  COOKIES_POLICY = '/cookies-policy',
  CONTINUE_MOVE = '/continue-move',
  ABOUT = '/about',
  FAQ = '/faq',
  HOW_IT_WORKS = '/how-it-works',
  AFFILIATE = '/affiliate',
  AFFILIATE_BECOME_PARTNER = '/affiliate-become-partner',
}

export enum RouterQueryParams {
  action = 'check',
}

export enum ALL_ROUTES {
  '/location'= '/location',
  '/date'= '/date',
  '/house'= '/house',
  '/room'= '/room',
  '/inventory'= '/inventory',
  '/people'= '/people',
  '/inventory-review'= '/inventory-review',
  '/quote'= '/quote',
  '/insurance'= '/insurance',
  '/checkout'= '/checkout',
  '/my-profile'= '/my-profile',
  '/trucker-landing'= '/trucker-landing',
  '/'= '/',
  '/sign-in'= '/sign-in',
  '/forgot-password'= '/forgot-password',
  '/trucker-become-partner' = '/trucker-become-partner',
  '/404' = '/404',
  '/set-new-password' = '/set-new-password',
  '/privacy-policy' = '/privacy-policy',
  '/terms-and-conditions' = '/terms-and-conditions',
  '/cookies-policy' = '/cookies-policy',
  '/continue-move' = '/continue-move',
  '/about' = '/about',
  '/faq' = '/faq',
  '/how-it-works' = '/how-it-works',
  '/affiliate' = '/affiliate',
  '/affiliate-become-partner' = '/affiliate-become-partner',
}

export type AllRoutesType = keyof typeof ALL_ROUTES;
