import { useContext } from 'react';

import { TimerContext } from 'context/timer-context';

import { MoveStepsEnum } from '@appTypes/enums/steps';

import { createInitMooveThunk, getCurrentStepMooveThunk } from '@store/features/order/order.thunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getRouteByStep } from '@utils/global';
import { useRouter } from './useRouter';
import { setActiveStepAction, setResetMoveAction } from '@store/features/order/order.actions';

export const useInitContinueAndResetMoveActions = () => {
  const { navigate } = useRouter();
  const { createTimeStamp, clearTimeStamp } = useContext(TimerContext);

  const dispatch = useAppDispatch();
  const hash = useAppSelector(({ order }) => order.hash);

  const handleGetStarted = (onClose?: () => void) => {
    // # Continue
    if (hash) {
      dispatch(getCurrentStepMooveThunk({ hash }))
        .unwrap()
        .then(({ data }) => {
          const currentRoute = getRouteByStep(data.step + 1);

          if (currentRoute) {
            navigate(currentRoute);
            onClose?.();
          }
        });

      return;
    }

    // # Get Started
    dispatch(createInitMooveThunk({ step: MoveStepsEnum.INIT, hash: '' }))
      .unwrap()
      .then(() => {
        createTimeStamp();
        dispatch(setActiveStepAction(MoveStepsEnum.LOCATION));
        navigate('/location');
        onClose?.();
      });
  };

  const handleResetMove = () => {
    if (hash) {
      dispatch(setResetMoveAction());
    }

    clearTimeStamp();
  };

  return ({
    handleGetStarted,
    handleResetMove,
  });
};
