import { ReactNode } from 'react';

import { ButtonProps } from '@mui/material/Button';

import { StyledButton } from './styled';

interface IButtonTestProps extends Omit<ButtonProps, 'color' | 'variant' | 'size'> {
  label?: ReactNode;
  color?: ButtonProps['color'];
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
}

const Button = ({
  label,
  children,
  size = 'small',
  color = 'primary',
  variant = 'contained',
  ...restProps
}: IButtonTestProps) => {
  return (
    <StyledButton
      size={size}
      color={color}
      variant={variant}
      disableElevation
      {...restProps}
    >
      {label || children}
    </StyledButton>
  );
};

export default Button;
