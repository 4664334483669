import { styled, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Modal from '@components/Modal';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FontFamilyNames } from '@appTypes/fonts';

import { COLORS } from '@utils/colors';

export const StyledModal = styled(Modal)(({ theme }: { theme: Theme }) => ({
  '.MuiPaper-root': {
    maxWidth: '1080px',
    maxHeight: '100%',
    height: 'fit-content',

    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
}));

export const StyledDialogContent = styled(DialogContent)(
  ({ theme }: { theme: Theme }) => ({
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'unset',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',

      '.MuiStack-root': {
        width: '100%',
      },
    },

    '@media (max-height: 1020px)': {
      '.MuiTypography-h1': {
        fontSize: '50px',
      },
    },
  }),
);

export const StyledAboutModalBody = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    padding: '100px 0 0 0',
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'unset',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',

      '.MuiStack-root': {
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 0 0',
    },

    '@media (max-height: 1020px)': {
      '.MuiTypography-h1': {
        fontSize: '50px',
      },
    },
  }),
);

export const LeftStack = styled(Stack)(({ theme }: { theme: Theme }) => ({
  padding: '50px',
  width: '50%',

  '@media (max-height: 1020px)': {
    padding: '40px 40px 50px',
  },

  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '41px 41px 62px 41px',

  },
  [theme.breakpoints.down('sm')]: {
    padding: '40px 20px 20px 20px',

  },
}));

export const RightPart = styled(Box)(() => ({
  position: 'relative',
  width: '50%',
  backgroundColor: COLORS.amour2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ImageBox = styled(Box)(({ theme }) => ({
  width: '478px',
  height: '511px',

  [theme.breakpoints.down('l')]: {
    width: '300px',
    height: '300px',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  right: 70,
  top: 50,
  color: COLORS.darkBlue,
  zIndex: 10,

  svg: {
    fontSize: '68px',
  },

  [theme.breakpoints.down('md')]: {
    right: '0',
    top: '0',
    background: theme.palette.secondary.main,
    borderRadius: '0',
    border: `1px solid ${theme.palette.common.black}`,
    width: '51px',
    height: '49px',

    ':hover': {
      background: theme.palette.secondary.main,
    },
  },
}));

export const StyledAboutContent = styled(Box)(({ theme }) => ({
  padding: '100px 0 20px 20px',
  background: COLORS.beige,
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('sm')]: {
    padding: '100px 20px 20px 20px',
  },
}));

export const AboutContent = styled(Box)(({ theme }) => ({
  padding: '100px 0 20px 20px',
  background: COLORS.beige,
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '100px 20px 0 20px',
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '64px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '30px',
    marginBottom: '20px',
  },
}));

export const StepperBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'end',
  marginTop: 33,
  marginBottom: 28,

  [theme.breakpoints.down('sm')]: {
    padding: '0',
    marginTop: 41,
  },
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '17px',
  fontWeight: 300,
  lineHeight: '135%',
  maxWidth: 950,

  [theme.breakpoints.down('l')]: {
    // width: '300px',
    // height: '300px',
  },
}));

export const EmailLink = styled('a')(() => ({
  color: COLORS.error.main,
  margin: '0 5px',
}));

export const Image = styled('img')(({ theme }) => ({
  marginTop: 120,

  [theme.breakpoints.down('sm')]: {
    marginTop: 40,
  },
}));
