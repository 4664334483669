import { useState } from 'react';

import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import AppBar from '@components/AppBar';
import MobileDrawer from '@components/MobileDrawer';

import { MOBILE_DRAWER_WIDTH } from '@utils/constants';
import { Main, StyledOutletContainer } from './styled';
import LoginModal from '@pages/LoginMoal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  clearErrorsAction,
  setIsOpenContactUsPopupAction,
  setIsOpenLoginPopupAction,
  setIsOpenRegisterPopupAction,
  setIsOpenResetPasswordPopupAction,
} from '@store/features/auth/auth.actions';
import Register from '@pages/Register';
import ContactUsPopup from '@pages/ContactUsPopup';
import {
  setIsTruckerAboutPopupOpen,
  setIsTruckerFaqPopupOpen,
  setIsTruckerHowItWorksPopupOpen,
} from '@store/features/app/app.slice';
import TruckerFaqPopup from '@pages/TruckerFaqPopup';
import { useRouter } from '@hooks/useRouter';
import TruckerAboutUs from '@pages/TruckerAboutUs';
import ResetPasswordPopup from '@pages/ResetPasswordPopup';
import TruckerHowItWorksPopup from '@pages/TruckerHowItWorksPopup';

const Layout = () => {
  const { location: { pathname } } = useRouter();

  const dispatch = useAppDispatch();

  const { isOpenLoginPopup, isOpenRegisterPopup, isOpenContactUsPopup, isOpenResetPasswordPopup } =
    useAppSelector((state) => state.auth);

  const { isTruckerFaqPopupOpen, isTruckerAboutPopupOpen,
    isTruckerHowItWorksPopupOpen,
  } = useAppSelector(
    (state) => state.app,
  );

  const [mobileDrawerIsOpen, setMobileDrawerIsOpen] = useState(false);

  const pathClassName = pathname.split('/').filter(Boolean)[0];
  const is404 = pathname === '/404';

  const handleDrawerToggle = () => {
    setMobileDrawerIsOpen(!mobileDrawerIsOpen);
  };

  const handleDrawerClose = () => {
    setMobileDrawerIsOpen(false);
  };

  const handleLoginModalClose = () => {
    dispatch(clearErrorsAction());
    dispatch(setIsOpenLoginPopupAction(false));
  };

  const handleRegisterModalClose = () => {
    dispatch(clearErrorsAction());
    dispatch(setIsOpenRegisterPopupAction(false));
  };

  const handleContactUsModalClose = () => {
    dispatch(setIsOpenContactUsPopupAction(false));
  };

  const handleTruckerFaqPopupClose = () => {
    dispatch(setIsTruckerFaqPopupOpen(false));
  };

  const handleTruckerAboutPopupClose = () => {
    dispatch(setIsTruckerAboutPopupOpen(false));
  };

  const handleResetPasswordPopupClose = () => {
    dispatch(setIsOpenResetPasswordPopupAction(false));
  };

  const handleTruckerHowItWorksPopupClose = () => {
    dispatch(setIsTruckerHowItWorksPopupOpen(false));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {!is404 && <AppBar handleDrawerToggle={handleDrawerToggle} />}

      <MobileDrawer
        open={mobileDrawerIsOpen}
        drawerWidth={MOBILE_DRAWER_WIDTH}
        handleDrawerClose={handleDrawerClose}
      />

      <Main open={mobileDrawerIsOpen}>
        <StyledOutletContainer className={pathClassName}>
          <Outlet />

          <LoginModal
            isOpen={isOpenLoginPopup}
            handleClose={handleLoginModalClose}
          />
          <Register
            isOpen={isOpenRegisterPopup}
            handleClose={handleRegisterModalClose}
            onLogin={() => {
              dispatch(setIsOpenRegisterPopupAction(false));
              dispatch(setIsOpenLoginPopupAction(true));
            }}
          />
          <ResetPasswordPopup
            isOpen={isOpenResetPasswordPopup}
            handleClose={handleResetPasswordPopupClose}
          />
          <ContactUsPopup
            isOpen={isOpenContactUsPopup}
            onClose={handleContactUsModalClose}
          />
          {/* <FaqPopup isOpen={isFaqPopupOpen} onClose={handleFaqPopupClose} /> */}
          <TruckerFaqPopup
            isOpen={isTruckerFaqPopupOpen}
            onClose={handleTruckerFaqPopupClose}
          />
          <TruckerAboutUs
            isOpen={isTruckerAboutPopupOpen}
            onClose={handleTruckerAboutPopupClose}
          />
          <TruckerHowItWorksPopup
            isOpen={isTruckerHowItWorksPopupOpen}
            onClose={handleTruckerHowItWorksPopupClose}
          />
        </StyledOutletContainer>
      </Main>
    </Box>
  );
};

export default Layout;
