/* eslint-disable @typescript-eslint/no-unused-vars */
import { RefObject } from 'react';

import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import every from 'lodash/every';

import { BackStepEnums, MoveStepsEnum } from '@appTypes/enums/steps';
import { AllRoutesType, PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages';

export const moveStepArray = Object.keys(MoveStepsEnum).filter((v) =>
  Number.isNaN(Number(v)));

export const toTitleCase = (str: string) => {
  return startCase(toLower(str));
};

export const scrollToRef = (ref: RefObject<HTMLDivElement>, gapY = 0) => {
  if (ref.current) {
    window.scrollTo(0, ref.current.offsetTop - gapY);
  }
};

export const dateRange = (
  startDate: Date | string,
  endDate: Date | string,
  steps = 1,
) => {
  const dateArray: Date[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(new Date(currentDate));
    // Use UTC date to prevent problems with time zones and DST
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }

  return dateArray;
};

export const getRouteByStep = (
  step: string | number,
): AllRoutesType => {
  // if (step === BackStepEnums.SIGN) {
  //   return '/quote';
  // }

  const routes: Record<string, string> = {
    [MoveStepsEnum.INIT]: PAGE_ROUTES_PRIVATE.LOCATION,
    [MoveStepsEnum.LOCATION]: PAGE_ROUTES_PRIVATE.LOCATION,
    [MoveStepsEnum.DATE]: PAGE_ROUTES_PRIVATE.DATE,
    [MoveStepsEnum.HOUSE]: PAGE_ROUTES_PRIVATE.HOUSE,
    [MoveStepsEnum.ROOM]: PAGE_ROUTES_PRIVATE.ROOM,
    [MoveStepsEnum.INVENTORY]: PAGE_ROUTES_PRIVATE.INVENTORY,
    [MoveStepsEnum.INVENTORY_REVIEW]: PAGE_ROUTES_PRIVATE.INVENTORY_REVIEW,
    [MoveStepsEnum.PEOPLE]: PAGE_ROUTES_PRIVATE.PEOPLE,
    [MoveStepsEnum.INSURANCE]: PAGE_ROUTES_PRIVATE.INSURANCE,
  };

  return routes[step] as AllRoutesType;
};

export const allTrueOrFalse = <T extends any = any>(
  arr: Array<T>,
  checkBy: boolean,
) => {
  return every(arr, (i: T) => (checkBy ? i : !i));
};
