import { toast } from 'react-toastify';
import { ToastContent, ToastOptions } from 'react-toastify/dist/types';

export enum TOAST_TYPES {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  DEFAULT = 'default',
}

const initialOptions: ToastOptions<{}> = {
  position: 'top-right',
  theme: 'light',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

class ToastService {
  static async success(message: ToastContent, options?: ToastOptions<{}> | undefined) {
    toast.success(message, {
      ...initialOptions,
      ...options,
      type: TOAST_TYPES.SUCCESS,
    });
  }

  static async error(message: ToastContent, options?: ToastOptions<{}> | undefined) {
    toast.error(message, {
      ...initialOptions,
      ...options,
      type: TOAST_TYPES.ERROR,
    });
  }

  static async info(message: ToastContent, options?: ToastOptions<{}> | undefined) {
    toast.info(message, {
      ...initialOptions,
      ...options,
      type: TOAST_TYPES.INFO,
    });
  }
}

export default ToastService;
