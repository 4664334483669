/* eslint-disable @typescript-eslint/no-unused-vars */
import { http } from 'api/RequestApi';
import { AxiosResponse } from 'axios';

import {
  AllMoveLastStep,
  LastStepSignature,
} from '@appTypes/model/lastStep.move';
import { ICalcPriceModel } from '@appTypes/model/calc.price.model';
import { AllMoveInfo, MoveType, ProfileMove } from '@appTypes/model/allMoveInfo.model';
import { DocumentActionStatuses } from '@appTypes/enums/steps';

import { MoveCreateModel } from '../types/model/create.move.model';

class MooveService {
  static prefix = '/moves';

  static async createMoveApi<T = any, R = AxiosResponse<T>>(data: T) {
    // TODO: Must remove after test
    // eslint-disable-next-line prefer-promise-reject-errors
    // return Promise.reject<any>('Error createMoveApiv');
    return http.post<T, R>(`${this.prefix}`, data);
  }

  static async getCurrentStep(id: MoveCreateModel['hash']) {
    // TODO: Test quotte page -> '?action=check'
    // if (
    //   window.location?.search === '?action=check' ||
    //   window.location.pathname === '/checkout' ||
    //   window.location.pathname === '/quote'
    // ) {
    //   const res: any = {
    //     step: 8,
    //     isSend: true,
    //     actionStatus: DocumentActionStatuses.MANUALLY_SIGNED,
    //     requestStatus: 'inprogress',
    //   } as LastStepSignature;

    //   return Promise.resolve({ data: res });
    // }

    return http.get<AllMoveLastStep>(`${this.prefix}/${id}/lastStep`);
  }

  static async getMoveInfo(id: MoveCreateModel['hash']) {
    return http.get<AllMoveInfo>(`${this.prefix}/${id}/info`);
  }

  static async getCheckDocumentStatus(id: MoveCreateModel['hash']) {
    // TODO: Test quotte page -> '?action=check'
    // if (
    //   window.location?.search === '?action=check' ||
    //   window.location.pathname === '/checkout' ||
    //   window.location.pathname === '/quote'
    // ) {
    //   const res: any = {
    //     step: 8,
    //     isSend: true,
    //     actionStatus: DocumentActionStatuses.MANUALLY_SIGNED,
    //     requestStatus: 'inprogress',
    //   } as LastStepSignature;

    //   return Promise.resolve({ data: res });
    // }

    return http.get<LastStepSignature>(`${this.prefix}/${id}/document/status`);
  }

  static async getPrice(id: MoveCreateModel['hash']) {
    return http.get<ICalcPriceModel>(`${this.prefix}/${id}/price`);
  }

  static async getMyMoves(type: MoveType, payable?: boolean) {
    return http.get<void, AxiosResponse<{data: ProfileMove[]; total: number}>>(`${this.prefix}/clients`, {
      params: { type, payable },
    });
  }

  static async cancel(id: string) {
    return http.delete(`${this.prefix}/${id}`);
  }

  static async changeDate(id: string, data: { fromDate: string; toDate: string }) {
    return http.put(`${this.prefix}/${id}/date`, data);
  }

  static async createIntent(id: string) {
    return http.post(`${this.prefix}/${id}/paymentIntent`);
  }

  static async changeAcceptStatus(id: string, accept: boolean) {
    return http.put(`${this.prefix}/${id}/accept`, { accept });
  }
}

export default MooveService;
